import React, { memo, useEffect, useState, useCallback } from "react";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import NoContentCard from "../../../atoms/NoContentCard";
import { useNavigate } from "react-router-dom";
import { getData } from "../../../../services";
import { useCookies } from "react-cookie";
import Pagination from "../../../atoms/Pagination";
import moment from "moment";
import View from "../../../icons/View";
import styles from './index.module.scss'

const Funds = () => {
  const [cookies] = useCookies(["t"]);

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [funderData, setFunderData] = useState([]);
  const [searchData, setSearchData] = useState();

  const GetFunderData = async () => {
    try {
      const res = await getData({
        endpoint: "/investor",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
        },
      });

      console.log("res", res?.data);
      setFunderData(res?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    GetFunderData();
  }, [currentPage, itemsPerPage, searchData]);

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title={"Funders"}
          // subtitle={content[type]?.subtitle}
          type="Pricing"
          classes="d-flex align-items-center"
        />
      </>
    );
  });

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const columns = [
    {
      field: "investor_name",
      headerName: "Funder Name",
      flex: 1,
    },

    {
      field: "investor_code",
      headerName: "Funder Code",
      flex: 1,
    },

    {
      field: "createdAt",
      headerName: "Created On",
      flex: 1,
      renderCell: (params) => {
        return moment(params.row.createdAt).format("YYYY-MM-DD");
      },
    },

    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <span type="button" className={styles.action_svg}>
            <View />
          </span>
        );
      },
    },
  ];
  return (
    <>
      <div className="headingSearchWrap">
        <div>
          <Heading className="HeadingStyle">Funds</Heading>
        </div>
        <div className="searchButton">
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />
        </div>
      </div>
      <CustomDatagrid
        rows={funderData?.[0]?.roleData || []}
        getRowId={(row) => row?._id}
        height={() => 50}
        hoverColor="f4f4f4"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        columns={columns}
        onCellClick={(data) => {
          navigate(`view/${data?.row._id}`, {
            state: {
              data: data?.row, // You can pass the entire row or other relevant data
            },
          });
        }}
      />

      {/* {funderData?.[0]?.pageDetails?.count > 10 && ( */}
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={funderData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      {/* )} */}
    </>
  );
};

export default Funds;
