import React from "react";
import { useState } from "react";
import { Heading } from "../../../../../../atoms/Heading";
import { DateSelector } from "../../../../../../atoms/MonthYearSelector";
import CustomButton from "../../../../../../atoms/CustomButton";
import CounterDropDown from "../../../../../../molecules/CounterDataDropdown";
import AnalysisView from "../anlysisView";

const AnalysisFileReview = () => {
  const [client, setClient] = useState();
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleReset = () => {
    setShow(false);
    setClient(null);
  };
  return (
    <>
      <Heading className={"HeadingSubStyle"}>
        Financial Analysis file Review
      </Heading>

      <div className="d-flex gap-3 mt-2">
        <div style={{ width: "32.426%" }}>
          <CounterDropDown
            placeholder="Client Name"
            onChange={(option) => {
              setClient(option);
              if (show) {
                setShow(false);
              }
            }}
            selectedValue={client?.value}
            onlyGroup
          />
        </div>
        <CustomButton
          type="btn-primary"
          text="Analyse"
          handleClick={handleShow}
          disabled={!client}
        />
        <CustomButton
          type="btn-outline-dark"
          text="Reset"
          handleClick={handleReset}
        />
      </div>

      {show && (
        <div className="mt-4">
          <AnalysisView id={client?.value} />
        </div>
      )}
    </>
  );
};

export default AnalysisFileReview;
