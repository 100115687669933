const MinusIcon = ({ color = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Bold"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path
        d="M16.5,13.5h-9a1.5,1.5,0,0,1,0-3h9a1.5,1.5,0,0,1,0,3Z"
        fill={color}
      />
    </svg>
  );
};
export default MinusIcon;
