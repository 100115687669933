import { Formik } from "formik";
import { Heading } from "../../../../atoms/Heading";
import style from "./index.module.scss";
import CustomButton from "../../../../atoms/CustomButton";
import { getData, patchData, postData } from "../../../../../services";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import TextInput from "../../../../atoms/TextInput";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import QuillEditor from "../../../../atoms/QuillEditor/QuillEditor";
import { DateSelector } from "../../../../atoms/MonthYearSelector";
import MyDropdown from "../../../../atoms/MyDropdown";
import CounterDropDown from "../../../../molecules/CounterDataDropdown";
import moment from "moment";
import MyInput from "../../../../atoms/MyInput";
import { LabelDisplay } from "../../counterparty/view";
import * as Yup from "yup";
import Modal from "../../../../atoms/modals/Modal";
import TagInput from "../../../../atoms/TagInput";
const PlatformUpdate = ({ type, mode = "" }) => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t", "un"]);
  const [priorListData, setPriorListData] = useState([]);
  const [priorData, setPriorData] = useState([]);
  const [initialDataByID, setIntitalDataByID] = useState([]);
  const { id } = useParams();
  const [loader, setLoader] = useState(false);

  const initialValues = {
    type: "platformUpdate",
    client_id: initialDataByID.client_id || "",
    client_name: initialDataByID.client_name || "",
    date: initialDataByID.note_date
      ? moment(initialDataByID.note_date).format("YYYY-MM-DD")
      : "",
    prior: "",
    details: {
      CP: initialDataByID.details?.CP || "",
      PIPELINE: initialDataByID.details?.PIPELINE || "",
      OTHERS: initialDataByID.details?.OTHERS || "",
    },
  };

  const validationSchema = Yup.object().shape({
    client_name: Yup.string().required("Client Name is required"),
  });

  const handleSubmit = async (status, values) => {
    if (status === "Active") {
      setLoader(true);
    }
    if (id) {
      try {
        console.log("Status :", status);
        const res = await patchData({
          endpoint: "notes/update",
          params: {
            id: id,
          },
          data: {
            type: values.type,
            client_name: values.client_name,
            note_date: values.date
              ? values.date
              : moment().utc().startOf("day").toISOString(),
            note_taker: atob(cookies.un),
            details: values.details,
            prior_call: values.prior,
            status: status,
          },
          token: cookies.t,
        });
        if (res) {
          toast.success("Notes Updated Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/notes");
        }
      } catch (err) {
        console.log("error", err);
      } finally {
        setLoader(false);
      }
    } else {
      try {
        console.log("Status :", status);
        const res = await postData({
          endpoint: "notes/create",
          data: {
            type: values.type,
            client_name: values.client_name,
            note_date: values.date
              ? values.date
              : moment().utc().startOf("day").toISOString(),
            note_taker: atob(cookies.un),
            details: values.details,
            prior_call: values.prior,
            status: status,
          },
          token: cookies.t,
        });
        if (res) {
          toast.success("Notes Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/notes");
        }
      } catch (err) {
        console.log("error", err);
      } finally {
        setLoader(false);
      }
    }
  };
  const getPriorCall = async () => {
    const res = await getData({
      endpoint: "notes/getPriorCallList",
      params: { note_type: initialValues.type },
      token: cookies.t,
    });
    setPriorListData(res?.data);
  };

  const getPriorDataByID = async (dataId, status) => {
    const res = await getData({
      endpoint: "notes/getNoteById",
      params: { id: dataId },
      token: cookies.t,
    });
    if (status === "initial") {
      setIntitalDataByID(res?.data);
    } else {
      setPriorData(res?.data);
    }
  };

  useEffect(() => {
    getPriorCall();
    if (id) {
      getPriorDataByID(id, "initial");
    }
  }, [id]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        key={Math.random}
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
      >
        {({
          isValid,
          dirty,
          setFieldValue,
          setFieldTouched,
          touched,
          errors,
          values,
          handleBlur,
          resetForm,
          isSubmitting,
        }) => (
          <>
            <Breadcrumb
              labels={[
                "Notes",
                `${mode === "View" ? "View" : "Create"} Platform Update`,
              ]}
              stepsToRemove={id ? (mode === "View" ? 2 : 1) : 0}
            />
            <Heading className="addHeaderStyle">
              {mode === "View" ? "View" : "Create"} Platform Update
            </Heading>
            <div
              className={`d-flex w-100 gap-3 ${mode !== "View" && "flex-wrap"}`}
            >
              {mode === "View" ? (
                <LabelDisplay
                  label={"Reference Name"}
                  value={values.client_name}
                />
              ) : (
                <MyInput
                  type="text"
                  name="client_name"
                  customholder="Reference Name"
                  width="23.9%"
                  required
                />
              )}
              {mode === "View" ? (
                <LabelDisplay label={"Note Date"} value={values.date} />
              ) : (
                <div style={{ width: "23.9%", position: "relative" }}>
                  <DateSelector
                    placeholder="Note Date"
                    onChange={async (option) => {
                      setFieldValue("date", option);
                    }}
                    selectedDate={values.date}
                  />
                </div>
              )}
              {mode !== "View" && (
                <MyDropdown
                  placeholder="Prior Call"
                  data={priorListData}
                  onChange={(option) => {
                    setFieldValue("prior", option);
                    getPriorDataByID(option);
                  }}
                  selectedValue={values?.prior}
                  width="35.8%"
                />
              )}
            </div>
            <div className="mt-2">
              <QuillEditor
                label="Current Positions"
                placeholderTextcription="Current Positions"
                onChange={(content) => setFieldValue(`details.CP`, content)}
                value={values.details.CP}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={"Collections Reporting, Operational and Other"}
                data={priorData?.details?.CP}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Pipeline"
                placeholderTextcription="Pipeline"
                onChange={(content) =>
                  setFieldValue(`details.PIPELINE`, content)
                }
                value={values.details.PIPELINE}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={"Deals in process, new deals"}
                data={priorData?.details?.PIPELINE}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div>
              <QuillEditor
                label="Other"
                placeholderTextcription="Other"
                onChange={(content) => setFieldValue(`details.OTHERS`, content)}
                value={values.details.OTHERS}
                type="faq"
                height="230px"
                hp="80%"
                classes="fixed-position-styles"
                placeholderText={"Team, origination efforts and initiatives"}
                data={priorData?.details?.OTHERS}
                mode={mode}
                dataHeading={`${priorData.client_name}-${moment(
                  priorData.note_date,
                ).format("DD-MM-YYYY")}-${priorData.note_taker}`}
              />
            </div>
            <div
              className={style.buttonWrap}
              style={{
                justifyContent: mode === "View" ? "end" : "space-between",
              }}
            >
              <div>
                <CustomButton
                  text="Back"
                  size="btn-lg"
                  type="btn-outline-dark"
                  handleClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
              {mode !== "View" && (
                <div className={style.resetAdd}>
                  <CustomButton
                    text="Save"
                    type="btn-outline-primary"
                    size="btn-lg"
                    handleClick={() => handleSubmit("Draft", values)}
                    disabled={!isValid || isSubmitting}
                  />
                  <button
                    className="btn-primary btn-lg p-2 px-4"
                    disabled={isSubmitting || loader}
                    onClick={() => {
                      handleSubmit("Active", values);
                    }}
                  >
                    Save & Share
                    {loader && (
                      <span
                        className="spinner-border spinner-border-sm ms-2 text-light"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
export default PlatformUpdate;
