const baseURL = process.env.REACT_APP_API_URL;

export const downloadReport = async ({ endpoint, token, fileName, params }) => {
  try {
    // Fetch the binary data from the API
    const sendurl = new URL(`${baseURL}/${endpoint}`);
    Object.keys(params).forEach((key) => {
      if (params[key] !== null && params[key] !== undefined) {
        if (Array.isArray(params[key])) {
          params[key].forEach((value) => {
            sendurl.searchParams.append(`${key}[]`, value);
          });
        } else {
          sendurl.searchParams.append(key, params[key]);
        }
      }
    });

    const response = await fetch(sendurl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },

    });

    // Check if the response is okay (status code 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Read the response as a blob
    const blob = await response.blob();

    // Log the blob for debugging purposes

    // Create a new Blob object for the Excel file
    const nblob = new Blob([blob], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a temporary URL for the Blob object
    const url = URL.createObjectURL(nblob);

    // Create an anchor element and trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    // Cleanup: remove the anchor element and revoke the object URL
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    return true;
  } catch (error) {
    console.error("Error downloading report:", error);
    return false;
  }
};
