import { useEffect, useState } from "react";
import CounterPartyBasicInfo from "../counterpartyBasicInfo";
import style from "./index.module.scss";
import { getData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";

const MonitoringCovenants = () => {
  const [cookies] = useCookies(["t"]);
  const { id } = useParams();
  const [clientData, setClientData] = useState({});
  const [covenantData, setCovenantData] = useState();

  const getCounterDataById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getCovenantDataById = async () => {
    try {
      const res = await getData({
        endpoint: "covenant/getCovenantClients",
        token: cookies.t,
        params: {
          client_id: id,
        },
      });
      setCovenantData(res?.data?.[0]?.covenant_details);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (id) {
      getCounterDataById();
      getCovenantDataById();
    }
  }, [id]);

  return (
    <>
      <div className={style.duesWrap}>
        <div className={style.clientBasicInfo}>
          <CounterPartyBasicInfo clientData={clientData} />
        </div>
        <div className={style.tableInfoWrap}>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                  Covenant
                </th>
                <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                  Covenant (Detail)
                </th>
                <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                  Frequency
                </th>
                <th style={{ border: "1px solid #DEE3E7", padding: "8px" }}>
                  Shift
                </th>
              </tr>
            </thead>
            <tbody>
              {covenantData?.length > 0 ? (
                <>
                  {covenantData?.map((item, index) => {
                    console.log("item", item);
                    return (
                      <>
                        <tr key={index}>
                          <td
                            style={{
                              border: "1px solid #DEE3E7",
                              padding: "8px",
                            }}
                          >
                            {item.name}
                          </td>
                          <td
                            style={{
                              border: "1px solid #DEE3E7",
                              padding: "8px",
                            }}
                          >
                            {item.long_description}
                          </td>
                          <td
                            style={{
                              border: "1px solid #DEE3E7",
                              padding: "8px",
                            }}
                          >
                            {item.frequency}
                          </td>
                          <td
                            style={{
                              border: "1px solid #DEE3E7",
                              padding: "8px",
                            }}
                          >
                            {item.monthly_shift}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td
                    colSpan={4}
                    style={{
                      border: "1px solid #DEE3E7",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default MonitoringCovenants;
