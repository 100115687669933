import React from 'react';

const Tools = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width='18'
    >
      <path d="m23.121,18.879l-7.68-7.681-1.411,1.398-5.01-5.01V3.422L3.129.025.044,3.109l3.398,5.891h4.164l5.003,5.003-1.431,1.417,7.7,7.701c.567.566,1.32.879,2.122.879s1.555-.312,2.121-.879c1.17-1.17,1.17-3.072,0-4.242ZM7.02,7h-2.422l-2.054-3.561.915-.916,3.562,2.055v2.422Zm14.687,14.707c-.377.379-1.036.379-1.414,0l-6.28-6.279,1.421-1.407,6.272,6.272c.39.39.39,1.024,0,1.414ZM11,6.863V2.374c1.369-1.457,3.307-2.374,5.46-2.374,1.181,0,2.318.272,3.381.811l1.231.624-4.819,4.818c-.189.189-.293.44-.293.707s.104.518.292.706c.379.379,1.037.378,1.415,0l4.823-4.823.628,1.214c.558,1.077.841,2.235.841,3.442,0,2.55-1.281,4.803-3.231,6.158l-1.447-1.447c1.601-.963,2.678-2.712,2.678-4.712,0-.398-.042-.789-.126-1.171l-2.752,2.752c-1.17,1.169-3.073,1.17-4.243,0-.566-.565-.879-1.319-.879-2.121s.312-1.555.879-2.121l2.727-2.727c-.362-.074-.73-.112-1.105-.112-2.817,0-5.143,2.13-5.46,4.863Zm-1.829,9.379l1.414,1.414-5.465,5.465c-.566.566-1.32.879-2.121.879s-1.555-.312-2.122-.879c-1.169-1.17-1.169-3.072,0-4.242l6.889-6.889,1.414,1.414-6.889,6.889c-.39.39-.39,1.024,0,1.414.378.379,1.037.379,1.414,0l5.465-5.465Z" />
    </svg>
  );
};

export default Tools;
