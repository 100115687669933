import React, { useState, useRef } from "react"; // Import the CSS file for styling
import styles from "./index.module.scss";
import Upload from "../../icons/Upload";
import toast from "react-hot-toast";
import { errorStyles } from "../../../assets/styles/components/toast";
import Error from "../Error";

const ImageSelector = ({
  placeholder = "Select An Image",
  handleFile,
  maxFileSize,
  aspect = 1,
  touched,
  errors,
  customStyles,
  multiple = false,
  required
}) => {
  const [imageFile, setImageFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click(); // Trigger the hidden input field
  };
  // Step 1
  const handleChange = (event) => {
    setImageFile(null);
    const file = event.target.files[0];

    const isExcel =
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel";
    const isWordDoc =
      file.type === "application/msword" || // .doc
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"; // .docx
    if (file) {
      // Check if the file size is less than 1 MB (1 MB = 1024 * 1024 bytes)
      if (file.size > maxFileSize * 1024 * 1024) {
        toast.error(`Image size should be less than ${maxFileSize} MB`, {
          duration: 2000,
          style: errorStyles,
        });
        return;
      }
      console.log("file", file);

      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = () => {
          // Create an image element to get the dimensions
          const img = new Image();
          img.src = reader.result;

          img.onload = () => {
            // Check if the image width and height are greater than or equal to your minimum size
            const minWidth = 100; // Set your minimum width
            const minHeight = 100; // Set your minimum height

            if (img.width >= minWidth && img.height >= minHeight) {
              setImageFile(file);
              handleFile(file);
            } else {
              toast.error(
                `Image dimensions should be at least ${minWidth}x${minHeight}`,
                {
                  duration: 2000,
                  style: errorStyles,
                },
              );
            }
          };
        };
        reader.readAsDataURL(file);
      } else if (file.type === "application/pdf" || isExcel || isWordDoc) {
        // Handle PDF file
        setImageFile(file);
        handleFile(file);
      } else {
        toast.error("Only JPG, PNG,PDF, XLSX and JPEG are allowed", {
          duration: 2000,
          style: errorStyles,
        });
      }
    } else {
      // console.error("Please select a valid image file.");
    }
  };

  return (
    <>
      <div className={styles.image_selector} style={customStyles}>
        <div className={styles.custom_ui} onClick={handleClick}>
          <Upload />
          <div className={`${styles.placeholder} ${required ? 'required-field' : ''}`}>{placeholder}</div>
        </div>
        {multiple
          ? ""
          : imageFile && (
              <div className={styles.image_name}>{imageFile?.name}</div>
            )}

        <input
          type="file"
          accept="image/pdf/xls/xlsx/doc/docx*"
          ref={fileInputRef}
          style={{ display: "none" }} // Hide the actual file input
          onChange={handleChange}
        />
      </div>
      {touched && errors ? <Error error={errors} type="small" /> : null}
    </>
  );
};

export default ImageSelector;
