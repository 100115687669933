import * as XLSX from "xlsx";

 export async function extractSheetNamesFromUrl(url) {
    try {
      // Fetch the file from the provided URL
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }
  
      const arrayBuffer = await response.arrayBuffer();
  
      // Parse the file using xlsx
      const workbook = XLSX.read(arrayBuffer, { type: "array" });
  
      // Extract and format sheet names
      const sheetNames = workbook.SheetNames.map((sheetName) => ({
        value: sheetName,
        label: sheetName, // Capitalize label
      }));
  
      return sheetNames;
    } catch (error) {
      console.error("Error extracting sheet names:", error);
      throw error;
    }
  }