import React, { memo, useCallback } from "react";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import NoContentCard from "../../../../atoms/NoContentCard";
import { useState, useEffect } from "react";
import { getData } from "../../../../../services";
import { useCookies } from "react-cookie";
import Pagination from "../../../../atoms/Pagination";
import moment from "moment";
import { formatNumberInternational } from "../../../../../helpers/capatalizeLetters";

const FundingSummary = ({ date }) => {
  const [loader, setLoader] = useState(true);
  const [cookies] = useCookies(["t"]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [portfolioData, setPortfolioData] = useState([]);

  const getFundingData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "funding/getAllFundings",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          status: ["Funded", "Closed"],
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
          report_type: "Dashboard Portfolio",
          fundingStartDate: date?.start_date?.replace(".000", ""),
          fundingEndDate: date?.end_date?.replace(".000", ""),
        },
      });
      setPortfolioData(res?.data);
    } catch (err) {
      console.log("errr", err);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    getFundingData();
  }, [itemsPerPage, currentPage, date]);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const columns = [
    {
      field: "client_name",
      headerName: "Client",
      flex: 0.8,
    },
    {
      field: "obligor_name",
      headerName: "Obligor",
      flex: 0.8,
    },
    {
      field: "funding_date",
      headerName: "Funding Date",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.funded_date).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "Due Date",
      headerName: "Due Date",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>
              {params.row?.due_date &&
                moment(params.row?.due_date).format("YYYY-MM-DD")}
            </span>
          </>
        );
      },
    },
    {
      field: "funder",
      headerName: "Participant",
      flex: 0.8,
    },
    {
      field: "currency",

      headerName: "Currency",
      flex: 0.8,
    },
    {
      field: "cost_value",
      headerName: "Cost",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <>
            <span>
              {params.row?.cost_value &&
                formatNumberInternational(params.row?.cost_value)}
            </span>
          </>
        );
      },
    },
    {
      field: "face_value",
      headerName: "Face Value",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <>
            <span>
              {params.row?.face_value &&
                formatNumberInternational(params.row?.face_value)}
            </span>
          </>
        );
      },
    },
  ];

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title={"No Data"}
          // subtitle={content[type].subtitle}
          type="Counterparty"
          classes="d-flex align-items-center"
          height={"7rem"}
          fontSize={"20px"}
        />
      </>
    );
  });
  return (
    <div>
      <CustomDatagrid
        columns={columns}
        rows={portfolioData?.[0]?.fundingData || []}
        getRowId={(row) => row?._id}
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        tHeight="350px"
        loader={loader}
      />
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={portfolioData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
        options={["5", "10", "15"]}
      />
    </div>
  );
};

export default FundingSummary;
