import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery"; // Import for responsive styling
import "./styles.scss";

const CustomDatagrid = ({
  rows,
  columns,
  CustomNoRowsOverlay,
  getRowId,
  height,
  tHeight = "31.25rem",
  checkRequired = false,
  onCellClick,
  hoverColor,
  loader = false,
  setSelectedRowIds = () => {},
}) => {
  const [isRowsVisible, setIsRowsVisible] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  // Use media query to detect small screens
  const isSmallScreen = useMediaQuery("(max-width:1550px)");

  // useEffect(() => {
  //   let timeoutId;

  //   if (rows && rows.length > 0) {
  //     setIsRowsVisible(true);
  //     setShowLoader(false);
  //   } else {
  //     timeoutId = setTimeout(() => {
  //       setIsRowsVisible(false);
  //       setShowLoader(false);
  //     }, 500);
  //   }

  //   return () => clearTimeout(timeoutId);
  // }, [rows]);

  useEffect(() => {
    if (rows && rows.length > 0) {
      setIsRowsVisible(true);
    } else {
      setIsRowsVisible(false);
    }
  }, [rows]);

  useEffect(() => {
    setIsRowsVisible(rows && rows.length > 0);
  }, [rows]);

  // Adjust column settings for small screens
  const modifiedColumns = columns?.map((column, index) => ({
    ...column,
    flex: column.flex, // Flex width for small screens
    hide: isSmallScreen && index > 1, // Hide non-essential columns on small screens
    sortable: true,
  }));

  const dynamicHeight = isRowsVisible ? "auto" : tHeight;
  const getRowHeight = () => (isSmallScreen ? height : height || 52);

  return (
    <Box
      sx={{
        minHeight: tHeight,
        marginBottom: "1.5rem",
        transition: "height 0.3s ease-in-out",
        height: dynamicHeight,
        fontFamily: "Instrument Sans",
      }}
    >
      <div
        className={`${
          isRowsVisible && !loader ? "" : "table-div"
        } h-100 mb-3 mt-3`}
        style={{ height: isRowsVisible && !loader ? "100%" : "0" }}
      >
        <DataGrid
          rows={rows}
          columns={modifiedColumns}
          getRowId={getRowId}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          onCellClick={onCellClick}
          checkboxSelection={checkRequired}
          loading={loader}
          onRowSelectionModelChange={(ids) => {
            if (setSelectedRowIds) {
              setSelectedRowIds(ids);
            }
          }}
          getRowHeight={getRowHeight} // Pass the function here// Reduce row height on small screens
          disableRowSelectionOnClick
          DisableMultipleRowSelection={true}
          hideFooterPagination={false}
          hideFooter
          disableColumnMenu
          className="custom-scrollbar"
          sx={{
            "&.MuiDataGrid-root": {
              border: "none",
              fontFamily: "Instrument Sans",
              fontSize: isSmallScreen ? "12px" : "14px", // Smaller font on small screens
            },
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#F1F3F5",
              padding: isSmallScreen ? "8px" : "8px",
            },
            "& .MuiDataGrid-cell": {
              fontSize: "14px",
              color: "#1B242C",
              whiteSpace: "normal", // Allow text wrapping
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
              color: "#555F6D",
              fontSize: isSmallScreen ? "12px" : "13px",
              whiteSpace: "normal", // Allow header text wrapping
            },
            "& .MuiCheckbox-root": {
              "& .MuiSvgIcon-root": {
                fill: "#9EA8B3",
              },
              "&.Mui-checked .MuiSvgIcon-root": {
                fill: "#146EF5",
              },
            },
            "& .MuiDataGrid-row:hover": {
              background: hoverColor,
            },
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          disableColumnFilter
        />
      </div>
    </Box>
  );
};

export default CustomDatagrid;
