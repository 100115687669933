import React from "react";

const Interest = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
    >
      <path d="m22.083,6.548l-.083-.065v-3.483c0-.552-.448-1-1-1s-1,.448-1,1v1.916l-4.917-3.852c-1.815-1.422-4.352-1.422-6.167,0L1.917,6.548c-1.218.954-1.917,2.389-1.917,3.936v8.516c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5v-8.516c0-1.547-.699-2.982-1.917-3.936Zm-.083,12.452c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3v-8.516c0-.928.419-1.789,1.15-2.362l7-5.484c.545-.427,1.197-.64,1.85-.64s1.306.213,1.85.64l7,5.484c.731.573,1.15,1.434,1.15,2.362v8.516Zm-5-1.5c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm-8.5-4.5c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5,1.5.672,1.5,1.5-.672,1.5-1.5,1.5Zm6.368-1.504l-4,7c-.185.323-.522.504-.869.504-.168,0-.339-.042-.495-.132-.479-.274-.646-.885-.372-1.364l4-7c.274-.479.885-.645,1.364-.372.479.274.646.885.372,1.364Z" />
    </svg>
  );
};

export default Interest;
