import React, { useEffect, useState } from "react";
import { Heading } from "../../../../../atoms/Heading";
import { useParams } from "react-router-dom";
import { getData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import VerticalBarChart from "./VerticalBarChart";
import NoContentCard from "../../../../../atoms/NoContentCard";
import { getCurrencyRate } from "../../../../../../services/currencyConvertor";

const ExposureGraph = ({ title, type }) => {
  const [graphData, setGraphData] = useState(null);
  const [currencyConversion, setCurrencyConversion] = useState(null);

  const [cookies] = useCookies(["t"]);

  useEffect(() => {
    const fetchCurrencyRate = async () => {
      try {
        const res = await getCurrencyRate({ date: new Date() });
        setCurrencyConversion(res);
      } catch (error) {
        console.error("Error fetching currency rate:", error);
      }
    };

    fetchCurrencyRate(); // Call the async function
  }, []);

  const getGraphData = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getExposureData",
        token: cookies.t,
        params: {
          id: id,
          type: type,
        },
      });

      console.log("graph", res?.data);
      setGraphData(res?.data);
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    getGraphData();
  }, [type]);

  const { id } = useParams();
  return (
    <div>
      <div className="headingSearchWrap">
        <div>
          <Heading className="HeadingSubStyle">{title}</Heading>
        </div>
      </div>
      <div>
        {graphData?.length > 0 ? (
          <VerticalBarChart
            data={graphData}
            currencyConversion={currencyConversion}
          />
        ) : (
          <div className="mt-3">
            <NoContentCard title="No Data Found" type={"Pricing"} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ExposureGraph;
