import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getData } from "../../../../../../services";
import { Heading } from "../../../../../atoms/Heading";
import VerticalBarChart from "./VerticalBarChart";
import NoContentCard from "../../../../../atoms/NoContentCard";

const ExposureBasedOnGraphs = ({ title, basedOn }) => {
  const [graphData, setGraphData] = useState(null);

  const [cookies] = useCookies(["t"]);

  const getGraphData = async () => {
    try {
      const res = await getData({
        endpoint: "/funding/funderGraphDataBasedOnDetail",
        token: cookies.t,
        params: {
          id: id,
          basedOn: basedOn,
        },
      });

      console.log("graph", res?.data);
      setGraphData(res?.data);
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    getGraphData();
  }, [basedOn]);

  const { id } = useParams();
  return (
    <div>
      <div>
        <div className="headingSearchWrap">
          <div>
            <Heading className="HeadingSubStyle">{title}</Heading>
          </div>
        </div>
        <div>
          {graphData?.length > 0 ? (
            <VerticalBarChart data={graphData} />
          ) : (
            <div className="mt-3">
              <NoContentCard title="No Data Found" type={"Pricing"} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExposureBasedOnGraphs;
