import React from "react";
import { useNavigate } from "react-router-dom";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import style from "./index.module.scss";
import { memo, useCallback, useEffect, useState } from "react";
import NoContentCard from "../../../atoms/NoContentCard";
import { useCookies } from "react-cookie";
import Pagination from "../../../atoms/Pagination";
import CustomButton from "../../../atoms/CustomButton";
import moment from "moment/moment";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import {
  counterPartyType,
  CurrencyData,
  GICSectorData,
  statusData,
  statusTypes,
  transactionType,
} from "../../../../utils/staticData";
import Upload from "../../../icons/Upload";
import { downloadReport } from "../../../../services/downloadService";
import { useSelector } from "react-redux";
import { getData } from "../../../../services";
import { formatNumberInternational } from "../../../../helpers/capatalizeLetters";

const ReportsSector = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Counterparties;
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [portfolioData, setPortfolioData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loader, setLoader] = useState(true);
  const getFundingData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "funding/getAllFundingsBySector",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          currency: filters?.["Currency"],
          status: filters?.["Status"]
            ? filters?.["Status"]
            : ["Funded", "Draft", "Closed", "Reject"],
          transactionType: filters?.["Transaction Type"],
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
      });
      setPortfolioData(res?.data);
    } catch (err) {
      console.log("errr", err);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    getFundingData();
  }, [itemsPerPage, currentPage, searchData, filters]);

  function getPopulateName(data) {
    switch (data.transaction_type) {
      case "Note":
      case "Revolver":
      case "Client":
        return data?.client_name;

      case "Factoring/AR":
      case "Obligor":
        return data?.obligor_name;

      case "Supplier Credit":
      case "Supplier Credit-1":
      case "Supplier":
        return data?.supplier_name;

      default:
        return "";
    }
  }

  const columns = [
    {
      field: "Obligor",
      headerName: "Obligor",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle} title={getPopulateName(params.row)}>
            {getPopulateName(params.row)}
          </div>
        );
      },
    },
    {
      field: "funder",
      headerName: "Funder",
      flex: 0.7,
    },
    {
      field: "Geography",
      headerName: "Geography",
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            className={style.valueStyle}
            title={
              params?.row?.populatedInfo &&
              params?.row?.populatedInfo?.[0]?.geography
            }
          >
            {params?.row?.populatedInfo && (
              <span>{params?.row?.populatedInfo?.[0]?.geography}</span>
            )}
          </div>
        );
      },
    },
    {
      field: "Industry",
      headerName: "Industry",
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            className={style.valueStyle}
            title={
              params?.row?.populatedInfo &&
              params?.row?.populatedInfo?.[0]?.gics_industry
            }
          >
            {params?.row?.populatedInfo && (
              <span>{params?.row?.populatedInfo?.[0]?.gics_industry}</span>
            )}
          </div>
        );
      },
    },
    {
      field: "Sector",
      headerName: "Sector",
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            className={style.valueStyle}
            title={
              params?.row?.populatedInfo &&
              params?.row?.populatedInfo?.[0]?.gics_sector
            }
          >
            {params?.row?.populatedInfo && (
              <span>{params?.row?.populatedInfo?.[0]?.gics_sector}</span>
            )}
          </div>
        );
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 1,
    },
    {
      field: "cost_value",
      headerName: "Cost",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <span>
            {params?.row?.cost_value &&
              formatNumberInternational(params?.row?.cost_value)}
          </span>
        );
      },
    },
    {
      field: "transaction_type",
      headerName: "Trans Type",
      flex: 1,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
    },
  ];

  const CustomNoRowsOverlay = memo(() => {
    return (
      !loader && (
        <>
          <NoContentCard
            title="No Sector/Geography created yet!"
            tag="Add Counterparties"
            type="Counterparty"
            classes="d-flex align-items-center"
          />
        </>
      )
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const filtersData = {
    "Transaction Type": transactionType,
    Currency: CurrencyData,
    Status: statusTypes,
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "funding/exportSector",
      token: cookies.t,
      fileName: "Sector&Geography",
      params: {
        search: searchData,
        transactionType: filters?.["Transaction Type"],
        currency: filters?.["Currency"],
        status: filters?.["Status"]
          ? filters?.["Status"]
          : ["Funded", "Draft", "Closed", "Reject"],
        group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
      },
    });

    if (res) {
      toast.success("Sector & Geography Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Sector/Geography</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            placeholder="Search by name"
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />

          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}
          <FilterComponent
            filterData={filtersData}
            onFilterChange={(filters) => {
              setFilters(filters);
            }}
          />
        </div>
      </div>
      <CustomDatagrid
        rows={portfolioData?.[0]?.fundingData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        loader={loader}
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {/* {portfolioData?.[0]?.pageDetails?.count > 10 && ( */}
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={portfolioData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
      {/* )} */}
    </>
  );
};

export default ReportsSector;
