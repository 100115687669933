import React from "react";
import ReactECharts from "echarts-for-react";
import { convertAndAddCurrencies } from "../helper/currency";

const PieChart = ({ data, conversionRate, type }) => {
  const calculateValue = (currencyData) => {
    // Assuming we want to sum up all USD values or return the specific one
    return convertAndAddCurrencies(currencyData, conversionRate, "number");
  };

  let chartData;

  // Map the data to the required format for the chart
  if (type === "exposure") {
    chartData = data.map((item) => ({
      value: calculateValue(item.currencies),
      name: item.name,
    }));
  } else {
    chartData = Object.entries(data).map(([funder, currencies]) => ({
      name: funder,
      value: calculateValue(currencies), // Sum all currency values for the funder
    }));
  }


  // Calculate dynamic height based on the number of data points
  const dynamicHeight = Math.max(300, 300 + (chartData.length * 5));


  // Chart options
  const chartOptions = {
    tooltip: {
      trigger: "item",
      formatter: "<strong>{b}:</strong> ${c} ({d}%)",
    },
    color: [
      "#1E90FF", // Dodger blue
      "#4682B4", // Steel blue
      "#2F4F4F", // Dark slate gray
      "#708090", // Slate gray
      "#000000", // Black
    ],
    legend: {
      orient: "horizontal",
      bottom: 10,
      // type: "auto",
      itemWidth: 10,  // Add this to make legend symbols smaller
      itemHeight: 10, // Add this to make legend symbols smaller
   
      textStyle: {
        color: "#333",
        fontSize: '10px', // Decrease font size of legend
      },
      padding: [0, 0, 0, 0],
    },
    grid: {
      // containLabel: true,
      bottom: "15%",
    },
    series: [
      {
        type: "pie",
        radius: ["40%", "70%"],
        data: chartData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "600px",
        margin: "auto",
        minHeight: `${dynamicHeight}px`,
      }}
    >
      <ReactECharts
        option={chartOptions}
        style={{ height: "100%", minHeight: `${dynamicHeight}px` }}
        // style={{ height: 'calc(100vw * 0.25)' }} // 2:1 aspect ratio
        opts={{ renderer: "canvas" }}
      />
    </div>
  );
};

export default PieChart;
