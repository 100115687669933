import { GICSectorData , GICSIndustryData} from "../utils/staticData";

export const getFilteredIndustries = (sectorValue) => {
    if (!sectorValue) return GICSIndustryData;
    
    // Extract the sector number from the beginning of the value (e.g., "10" from "10 Energy")
    const sectorPrefix = sectorValue.split(' ')[0];
    
    // Filter industries that start with the same prefix
    return GICSIndustryData.filter(industry => 
      industry.label.startsWith(sectorPrefix)
    );
  };