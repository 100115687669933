import React, { useEffect, useState } from "react";
import Checkbox from "../../../../../atoms/Checkbox";
import "./styles.scss";

// const PermissionSelector = ({ label, onChange, selected }) => {
//   const [selectedPermissions, setSelectedPermissions] = useState({});

//   const types = ["view", "add/edit", "export", "delete"];

//   useEffect(() => {
//     if (selected && typeof selected === "object") {
//       setSelectedPermissions(selected);
//     }
//   }, [selected]);

//   const handleCheckboxChange = (type) => {
//     const key = type;
//     setSelectedPermissions((prev) => ({
//       ...prev,
//       [key]: !prev?.[key],
//     }));
//   };

//   console.log("Selected Options :", selectedPermissions);

//   useEffect(() => {
//     if (onChange && Object.keys(selectedPermissions).length > 0) {
//       onChange({ ...selectedPermissions, module: label });
//     }
//   }, [selectedPermissions]);

//   return (
//     <tr className="permission_selector">
//       <td>{label}</td>
//       {types.map((type) => {
//         const key = type;
//         return (
//           <td key={key}>
//             <Checkbox
//               name={type}
//               checked={!!selectedPermissions?.[key]}
//               onChange={() => handleCheckboxChange(type)}
//             />
//           </td>
//         );
//       })}
//     </tr>
//   );
// };

const PermissionSelector = ({ label, onChange, selected, allowed }) => {
  const [selectedPermissions, setSelectedPermissions] = useState({});

  const types = allowed || ["view", "add/edit", "export", "delete"];
  useEffect(() => {
    if (selected && typeof selected === "object") {
      setSelectedPermissions(selected);
    }
  }, [selected]);

  const handleCheckboxChange = (type) => {
    const updatedPermissions = {
      ...selectedPermissions,
      [type]: !selectedPermissions[type],
    };

    setSelectedPermissions(updatedPermissions);

    if (onChange) {
      onChange({ ...updatedPermissions, module: label });
    }
  };

  return (
    <tr className="permission_selector">
      <td>{label}</td>
      {types.map((type) => {
        return (
          <td>
            {type === "none" ? (
              <></>
            ) : (
              <Checkbox
                name={type}
                checked={!!selectedPermissions[type]}
                onChange={() => handleCheckboxChange(type)}
              />
            )}
          </td>
        );
      })}
    </tr>
  );
};

export default PermissionSelector;
