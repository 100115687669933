import { useCookies } from "react-cookie";
import { getData, patchData } from "../../../../../../services";
import CustomButton from "../../../../../atoms/CustomButton";
import style from "./index.module.scss";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import { Heading } from "../../../../../atoms/Heading";

const AdvReqModal = ({
  modalStateAdvReq,
  setModalStateAdvReq,
  getAllFundingStatus,
}) => {
  const [cookies] = useCookies(["t"]);
  const [fundingData, setFundingData] = useState({});
  const [clientData, setClientData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // const [activeTab, setActiveTab] = useState("Credit info");

  // const onTabChange = (event, value) => {
  //   setActiveTab(value);
  // };

  // const _getComponent = (tabs) => {
  //   const item = data.find((item) => item.label === tabs);
  //   return item ? item.view : null;
  // };

  // const data = [
  //   {
  //     id: 1,
  //     label: "Credit info",
  //     view: <CreditInfo fundingData={fundingData} clientData={clientData} />,
  //   },
  //   {
  //     id: 1,
  //     label: "See Obligor Dues",
  //     view: <SeeObligorDues />,
  //   },
  //   {
  //     id: 1,
  //     label: "OCR",
  //     view: <OCRComponent />,
  //   },
  //   {
  //     id: 1,
  //     label: "Others",
  //     view: <Others />,
  //   },
  // ];

  const getFundingById = async () => {
    try {
      const res = await getData({
        endpoint: "funding/getFundingById",
        token: cookies.t,
        params: {
          id: modalStateAdvReq.modalId,
        },
      });
      setFundingData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getClientById = async () => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: fundingData?.client_id,
        },
      });
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (fundingData?.client_id) {
      getClientById();
    }
  }, [fundingData?.client_id]);

  useEffect(() => {
    if (modalStateAdvReq.modalId) {
      getFundingById();
    }
  }, [modalStateAdvReq.modalId]);

  const handleRejectFunding = async () => {
    try {
      const res = await patchData({
        endpoint: "funding/UpdateFundingStatus",
        token: cookies.t,
        params: {
          id: modalStateAdvReq.modalId,
        },
        data: {
          status: "Reject",
        },
      });
      if (res) {
        toast.success("Funding Rejected Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
      }
      setModalStateAdvReq(() => ({
        isModalOpen: false,
      }));
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleSubmittedFunding = async () => {
    try {
      setIsLoading(true);
      const res = await patchData({
        endpoint: "funding/UpdateFundingStatus",
        token: cookies.t,
        params: {
          id: modalStateAdvReq.modalId,
        },
        data: {
          status: "Submitted",
        },
      });
      if (res) {
        toast.success("Funding Submitted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        setIsLoading(false);
      }
      setModalStateAdvReq(() => ({
        isModalOpen: false,
      }));
      getAllFundingStatus();
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <Heading className="HeadingStyle">
        Please certify the following facts:
      </Heading>
      <Heading className="HeadingSubStyle">
        - All supporting documents are true and correct.
      </Heading>
      <Heading className="HeadingSubStyle">
        - This request is made persuant to valid and enforcable agreement.
      </Heading>
      <Heading className="HeadingSubStyle">
        - There are no know reasons that may give arrise to a loss.
      </Heading>
      <Heading className="HeadingSubStyle">
        - You are currently in good standing under the transaction agreement.
      </Heading>
      <Heading className="HeadingSubStyle">
        - You are currently in compliance with the transation agreement.
      </Heading>
      <Heading className="HeadingSubStyle">
        - You will provide additional supporting documation that may be
        reasonably requested upon request.
      </Heading>
      <Heading className="HeadingSubStyle">
        - You have provided all required reporting.
      </Heading>
      {/* <TabStrip activeTab={activeTab} onTabChange={onTabChange} data={data} />
      <Box sx={{ width: "100%" }}>
        <Box>{_getComponent(activeTab)}</Box>
      </Box> */}

      <div className={style.buttonStyle}>
        {/* <CustomButton
          text="I Certify and confirm the above, Submit Request for Funding"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            handleSubmittedFunding();
          }}
        /> */}
        <button
          className="btn-primary btn-lg"
          type="submit"
          style={{ width: "100%" }}
          onClick={() => {
            handleSubmittedFunding();
          }}
        >
          I Certify and confirm the above, Submit Request for Funding
          {isLoading && (
            <span
              className="spinner-border spinner-border-sm ms-2 text-light"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </button>
        <CustomButton
          text="Decline"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            // handleRejectFunding();
            setModalStateAdvReq(() => ({
              isModalOpen: false,
            }));
          }}
        />
        {/* <CustomButton
          text="Cancel"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            setModalStateAdvReq(() => ({
              isModalOpen: false,
            }));
          }}
        /> */}
      </div>
    </>
  );
};
export default AdvReqModal;
//Sub module
// const CreditInfo = ({ fundingData, clientData }) => {
//   return (
//     <div>
//       <div className="row">
//         <label className={style.terms}>Asset Info</label>
//         <div className="col-6">
//           <div className={style.value}>Cost</div>
//         </div>
//         <div className="col-6">
//           <div className={style.value}>{fundingData?.cost_value}</div>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-6">
//           <div className={style.value}>Face Value</div>
//         </div>
//         <div className="col-6">
//           <div className={style.value}>
//             {parseFloat(fundingData?.cost_value) +
//               parseFloat(fundingData?.face_value)}
//           </div>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-6">
//           <div className={style.value}>Currency</div>
//         </div>
//         <div className="col-6">
//           <div className={style.value}>{fundingData?.currency}</div>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-6">
//           <div className={style.value}>Schedule status</div>
//         </div>
//         <div className="col-6">
//           <div className={style.value}>document complete</div>
//         </div>
//       </div>
//       <div className="row">
//         <label className={style.terms}>Obligor Information</label>
//         <div className="col-6">
//           <div className={style.value}>Name</div>
//         </div>
//         <div className="col-6">
//           <div className={style.value}>{clientData?.counter_name}</div>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-6">
//           <div className={style.value}>Internal Credit Limit</div>
//         </div>
//         <div className="col-6">
//           <div className={style.value}>{clientData?.credit_limit}</div>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-6">
//           <div className={style.value}>Credit Used</div>
//         </div>
//         <div className="col-6">
//           <div className={style.value}>{fundingData?.cost_value}</div>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-6">
//           <div className={style.value}>Credit Remaining</div>
//         </div>
//         <div className="col-6">
//           <div className={style.value}>
//             {parseFloat(clientData?.credit_limit) -
//               parseFloat(fundingData?.cost_value)}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const SeeObligorDues = () => {
//   return (
//     <>
//       <Heading className="HeadingStyle">See Obligor dues</Heading>
//     </>
//   );
// };
// const OCRComponent = () => {
//   return (
//     <>
//       <Heading className="HeadingStyle"> OCR</Heading>
//     </>
//   );
// };
// const Others = () => {
//   return (
//     <>
//       <Heading className="HeadingStyle"> Others</Heading>
//     </>
//   );
// };
