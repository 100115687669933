const View = ({ className = "", width = "25", height = "25" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"20"}
      height={"20"}
      viewBox="0 -1 16 16"
      fill="none"
    >
      <path
        d="M0.546671 6.2595C1.82992 9.75 4.75642 12 8.00015 12C11.2439 12 14.1704 9.75 15.4536 6.2595C15.5159 6.0921 15.5159 5.9079 15.4536 5.7405C14.1704 2.25 11.2439 0 8.00015 0C4.75642 0 1.82992 2.25 0.546671 5.7405C0.484443 5.9079 0.484443 6.0921 0.546671 6.2595ZM8.00015 1.5C10.5299 1.5 12.8384 3.25725 13.9469 6C12.8384 8.74275 10.5299 10.5 8.00015 10.5C5.47042 10.5 3.16192 8.74275 2.05342 6C3.16192 3.25725 5.47042 1.5 8.00015 1.5ZM8.00015 9C8.59355 9 9.17352 8.82405 9.66687 8.49443C10.1602 8.1648 10.5447 7.6962 10.7718 7.14803C10.9989 6.59985 11.0583 5.9967 10.9425 5.4147C10.8267 4.83278 10.5411 4.29824 10.1215 3.87868C9.7019 3.45912 9.16737 3.1734 8.58545 3.05765C8.00352 2.94189 7.4003 3.0013 6.85212 3.22836C6.30394 3.45542 5.83541 3.83994 5.50576 4.33329C5.17612 4.82663 5.00017 5.40668 5.00017 6C5.00017 6.79568 5.31624 7.55873 5.87885 8.1213C6.44146 8.68395 7.20455 9 8.00015 9ZM8.00015 4.5C8.29685 4.5 8.58687 4.58798 8.83355 4.75283C9.08022 4.9176 9.27245 5.1519 9.386 5.42595C9.49955 5.70008 9.52925 6.00165 9.47135 6.29265C9.41345 6.58358 9.27057 6.85088 9.0608 7.06065C8.85102 7.27043 8.5838 7.4133 8.2928 7.4712C8.0018 7.52903 7.70022 7.49933 7.42617 7.38585C7.15205 7.2723 6.91782 7.08 6.75297 6.83333C6.58812 6.58665 6.50015 6.2967 6.50015 6C6.50015 5.6022 6.65817 5.22068 6.9395 4.93935C7.22082 4.65803 7.60235 4.5 8.00015 4.5Z"
        fill="#555F6D"
      />
    </svg>
  );
};
export default View;
