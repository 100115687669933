import React from 'react';

const Delete = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.00008 5.83333V15C5.00008 15.9205 5.74627 16.6667 6.66675 16.6667H13.3334C14.2539 16.6667 15.0001 15.9205 15.0001 15V5.83333M5.00008 5.83333H4.16675M5.00008 5.83333H6.66675M15.0001 5.83333H15.8334M15.0001 5.83333H13.3334M6.66675 5.83333V4.16667C6.66675 3.24619 7.41294 2.5 8.33341 2.5H11.6667C12.5872 2.5 13.3334 3.24619 13.3334 4.16667V5.83333M6.66675 5.83333H13.3334M8.33341 9.16667V13.3333M11.6667 9.16667V13.3333"
        stroke="#3b424a"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Delete;
