import { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { Heading } from "../../../../../atoms/Heading";
import { DateSelector } from "../../../../../atoms/MonthYearSelector";
import CustomButton from "../../../../../atoms/CustomButton";
import style from "../index.module.scss";

const Valuation = () => {
  const [reportingDate, setReportingDate] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleConfirmDate = () => {
    if (reportingDate) {
      setButtonClicked(true); // Update buttonClicked state
      navigate(`/dashboard/monitoring/valuation/${reportingDate}`); // Navigate with date
    } else {
      alert("Please select a date before confirming.");
    }
  };

  return (
    <>
      <Heading className="HeadingStyle">Valuation Monitoring</Heading>
      {!buttonClicked && (
        <div className={style.dateButtonWrap}>
          <div style={{ position: "relative", width: "32.426%" }}>
            <DateSelector
              placeholder={"Select Reporting As of Date"}
              onChange={(option) => {
                setReportingDate(option);
              }}
              selectedDate={reportingDate}
            />
          </div>
          <div>
            <CustomButton
              text="Confirm Date"
              type="btn-primary"
              size="btn-lg"
              handleClick={handleConfirmDate} // Use handleConfirmDate
            />
          </div>
        </div>
      )}
      {buttonClicked && reportingDate && (
        <>
          <div style={{ width: "32.426%" }}>
            <CustomButton
              text="Print The Report"
              type="btn-primary"
              size="btn-lg"
              handleClick={() => {
                console.log("Printing report...");
              }}
            />
          </div>
        </>
      )}
    </>
  );
};
export default Valuation;


