import { useCookies } from 'react-cookie';
import toast from 'react-hot-toast';
import {
  confimationStyles,
  errorStyles,
} from '../assets/styles/components/toast';
import axios from '../axios';

export const useDelete = (userRole) => {
  const [cookies] = useCookies(['t']);

  const deleteItem = async (endpoint, name, params, invalidate) => {
    try {
      const response = await axios.delete(endpoint, {
        headers: {
          Authorization: `Bearer ${cookies.t}`,
          'USER-ROLE': userRole,
        },
        params: params,
      });

      if (response.status === 200 && response.data.statusCode) {
        toast.success(`${name} Deleted Successfully`, {
          duration: 1000,
          style: confimationStyles,
        });
      } else {
        toast.error(`${response.data.error}`, {
          duration: 2000,
          style: errorStyles,
        });
      }

      return response.data;
    } catch (error) {
      console.log(error);
      toast.error(`${error.response.data.error || 'An Error Occured'}`, {
        duration: 2000,
        style: errorStyles,
      });
    }
  };

  return deleteItem;
};
