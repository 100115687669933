import React, { useState } from "react";
import './styles.scss'

const ViewMore = ({ data , lineClamp = 5}) => {
  const [isViewMore, setIsViewMore] = useState(false);
  return (<>
  <div className={isViewMore ? '' : 'clamp-text'} style={{WebkitLineClamp : !isViewMore ? lineClamp : null}}>{data}</div>
  
  {data && <div className="view-more" onClick={() => setIsViewMore((prev) => !prev)}>{isViewMore ? 'View Less ': 'View More' }</div>}
  </>);
};

export default ViewMore;
