import React, { useMemo, useState } from "react";
import { Heading } from "../../../../../atoms/Heading";
import TabStrip from "../../../../../atoms/tabstrip";
import { Box } from "@mui/material";
import NewAnalysis from "./NewAnalysis";
import ReportingFileView from "./ReportingFileView";
import AnalysisFileReview from "./AnalysisFileReview";

const FinancialAnalysis = () => {
  const [activetab, setActiveTab] = useState("New Analysis");

  const data = useMemo(() => [
    { id: 1, label: "New Analysis" , view : <NewAnalysis/>},
    { id: 2, label: "Reporting File view" , view : <ReportingFileView/>},
    { id: 3, label: "Analysis File Review", view : <AnalysisFileReview/>},
  ],[]);

  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };
  return (
    <>
      <div className="fixed-part">
        <Heading className="HeadingStyle">Financial Analysis</Heading>

        <TabStrip activeTab={activetab} onTabChange={onTabChange} data={data} />
      </div>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ py: 2 }}>{_getComponent(activetab)}</Box>
      </Box>

      {/* <div style={styles.container}>
        <h3>Financial Analysis</h3>

        <div style={styles.section}>
          <strong>New Analysis:</strong>
          <a href="/dashboard/monitoring/analysis" style={styles.link}>
            Financial Analysis File Upload
          </a>
        </div>

        <div style={styles.section}>
          <strong>Reporting File Review:</strong>
          <a href="/dashboard/monitoring/analysis" style={styles.link}>
            Reporting and Financial Analysis Review
          </a>
        </div>

        <div style={styles.section}>
          <strong>Analysis File Review:</strong>
          <a href="/dashboard/monitoring/analysis/review" style={styles.link}>
            Financial Analysis Review
          </a>
        </div>
      </div> */}
    </>
  );
};

const styles = {
  container: {
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  },
  section: {
    marginBottom: "20px",
    padding: "10px",
    paddingLeft: "0px",
    borderRadius: "4px",
  },
  link: {
    display: "block",
    marginTop: "5px",
    color: "#0066cc",
    textDecoration: "none",
  },
};

export default FinancialAnalysis;
