import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import style from "./index.module.scss";
import TextInput from "../../../atoms/TextInput";
import MyDropdown from "../../../atoms/MyDropdown";
import { getData, patchData } from "../../../../services";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../assets/styles/components/toast";
import { Heading } from "../../../atoms/Heading";
import CustomButton from "../../../atoms/CustomButton";
import ChangePassword from "./change-password";
const EditProfile = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [cookies] = useCookies(["cid", "t"]);

  const getUserInfo = async () => {
    try {
      const res = await getData({
        endpoint: "adminAuth/getUserById",
        token: cookies.t,
        params: {
          id: cookies.cid,
        },
      });
      setProfile(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getUserInfo();
  }, []);

  const getRoleInfo = async () => {
    try {
      const res = await getData({
        endpoint: "role/getRolesList",
        token: cookies.t,
      });
      setRoleData(res);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getRoleInfo();
  }, []);

  const initialValues = {
    name: profile.name || "",
    email: profile.email || "",
    role_id: profile?.role_id || "",
  };
  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().required("Email is required"),
    role_id: yup.string().required("Role is required"),
  });
  const handleSubmit = async (values) => {
    try {
      const res = await patchData({
        endpoint: "adminAuth/updateUser",
        token: cookies.t,
        params: {},
        data: values,
      });
      if (res) {
        toast.success("Profile Updated Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <>
            <Heading className="HeadingStyle mb-1">Edit Profile</Heading>
<p className="mb-2">Change your profile details</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          setFieldValue,
          handleChange,
          handleBlur,
          touched,
          errors,
          values,
        }) => (
          <div className="edit-profile-div">
            <div className="row mt-3">
              <div className="col-6">
                <TextInput type="text" name="name" customholder="Name" />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <TextInput type="text" name="email" customholder="Email" isDisabled/>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <MyDropdown
                  data={roleData}
                  placeholder="Role"
                  onChange={(option) => {
                    setFieldValue("role_id", option);
                  }}
                  selectedValue={profile.role_id}
                  isDisabled
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-6">
              <div className="d-flex gap-3 justify-content-end">
      

              <CustomButton handleClick={() => navigate(-1)} size="btn-lg" text="Back"/>

<CustomButton type="btn-primary" handleClick={() => handleSubmit(values)} size="btn-lg" text="Update" buttonType="submit"/>
           
            </div>
              </div>
            </div>
        
          </div>
        )}
      </Formik>

    </>
  );
};
export default EditProfile;
