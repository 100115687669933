import React, { useState, useRef } from 'react';
import useScreenWidth from '../../../hooks/useScreenwidth';
import SearchIcon from '../../icons/SearchIcon';
import './search.scss';

const Search = ({
  onSearchEmit,
  placeholder = 'Search',
  className,
  variant = 'search',
  style,
  phonestyle,
  bgStyle,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const screenWidth = useScreenWidth();
  const typingTimeoutRef = useRef(null); // Ref to store timeout ID

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    // Clear any previously set timeout
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    // Set a new timeout to emit the search value
    typingTimeoutRef.current = setTimeout(() => {
      if (newValue?.length >= 3 || newValue === '') {
        onSearchEmit(newValue);
      }
    }, 300); // Trigger after 300ms of inactivity
  };

  // Concatenate the provided className with the existing classes
  const combinedClassName = `search-container ${variant} ${bgStyle} ${className || ''}`;

  return (
    <div
      className={combinedClassName}
      style={screenWidth < 500 ? phonestyle : style}
    >
      <div
        className="input-group"
        style={{ width: '100%' }}
        title={placeholder}
      >
        <div className="input-group-prepend">
          <span
            className={`input-group-text ${
              isFocused ? 'search-prepend-focused' : ''
            }`}
            id="basic-addon1"
          >
            <i className="d-flex mt-1 mb-1">
              <SearchIcon />
            </i>
          </span>
        </div>
        <input
          type="text"
          className={`form-control ${isFocused ? 'search-focused' : ''}`}
          placeholder={placeholder}
          aria-label="Search"
          aria-describedby="basic-addon1"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          value={inputValue}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default Search;
