import { formatNumberInternational } from "../../../../../helpers/capatalizeLetters";

export const convertAndAddCurrencies = (
  currencyValues,
  conversionRates,
  type = "string",
) => {
  // Convert GBP to USD
  let gbpInUsd = 0; // Initialize as 0
  if (currencyValues?.GBP && conversionRates?.gbp) {
    gbpInUsd = currencyValues.GBP * (1 / conversionRates?.gbp);
  }
  // Convert EUR to USD
  let eurInUsd = 0; // Initialize as 0
  if (currencyValues?.EUR && conversionRates?.eur) {
    eurInUsd = currencyValues.EUR * (1 / conversionRates?.eur);
  }

  // Add all the values together (ensure USD is defined)
  const totalUsd = (currencyValues?.USD || 0) + gbpInUsd + eurInUsd;

  // Log the final result
  const roundedTotalUsd = parseFloat(totalUsd.toFixed(2));

  if (type === "string") {
    return `$${roundedTotalUsd}`;
  } 
  if (type === "million") {
    const inMillions = (roundedTotalUsd / 1000000).toFixed(2);
    return `$${inMillions}M`;
  }
  else {
    return roundedTotalUsd;
  }
};
