const RoundCheck = ({ checked = false }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_18_1734)">
        <mask
          id="mask0_18_1734"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <path d="M20 0H0V20H20V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_18_1734)">
          <path
            d="M13.869 7.37943L8.62785 12.6206L6.13281 10.1256"
            stroke={checked ? '#1CA076' : '#7D8B99'}
            stroke-width="1.71875"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <mask
            id="mask1_18_1734"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
          >
            <path d="M0 1.90735e-06H20V20H0V1.90735e-06Z" fill="white" />
          </mask>
          <g mask="url(#mask1_18_1734)">
            <path
              d="M19.2187 10C19.2187 15.0914 15.0914 19.2188 10 19.2188C4.90863 19.2188 0.78125 15.0914 0.78125 10C0.78125 4.90863 4.90863 0.781252 10 0.781252C15.0914 0.781252 19.2187 4.90863 19.2187 10Z"
              stroke={checked ? '#1CA076' : '#7D8B99'}
              stroke-width="1.71875"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_18_1734">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundCheck;
