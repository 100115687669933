import React, { useState } from "react";
import { Heading } from "../../../../../../atoms/Heading";
import CounterDropDown from "../../../../../../molecules/CounterDataDropdown";
import { DateSelector } from "../../../../../../atoms/MonthYearSelector";
import CustomButton from "../../../../../../atoms/CustomButton";
import FileUpload from "../FileUpload";

const NewAnalysis = () => {
  const [client, setClient] = useState();
  const [date, setDate] = useState();
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleReset = () => {
    setShow(false)
    setClient(null)
    setDate(null)
  }
  return (
    <>
      <Heading className={"HeadingSubStyle"}>
        Financial Analysis File Upload
      </Heading>

      <div className="d-flex gap-3 mt-2">
        <div style={{ width: "32.426%" }}>
          <CounterDropDown
            placeholder="Client Name"
            onChange={(option) => {
              console.log("op,", option);
              setClient(option);
              if(show){
                setShow(false)
              }
            }}
            selectedValue={client?.value}
            onlyGroup
          />
        </div>
        <div style={{ position: "relative", width: "32.426%" }}>
          <DateSelector
            placeholder={"Select Reporting As of Date"}
            onChange={(option) => {
              console.log("op", option);
              setDate(option);
            }}
            selectedDate={date}
          />
        </div>

        <CustomButton
          type="btn-primary"
          text="Upload"
          handleClick={handleShow}
          disabled={!date || !client}
        />
      </div>

{show && 
<div className="mt-4">
<FileUpload client={client} date={date} reset={handleReset}/>
</div>
}

    </>
  );
};

export default NewAnalysis;
