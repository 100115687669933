import React from 'react';

const InputLayout = ({ children, classes }) => {
  return (
    <div className={`${classes} col-sm `}>
      <div className="form-group">{children}</div>
    </div>
  );
};

export default InputLayout;
