export const expitationTime = (): Date => {
    const expirationTime = new Date();
    expirationTime.setHours(expirationTime.getHours() + 12); // Add 2 minutes
    return expirationTime;
}

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const numberRegex = /^[0-9]+$/;
// export const expirationTime = (): Date => {
//     const expirationTime = new Date();
//     expirationTime.setMinutes(expirationTime.getMinutes() + 1); // Add 1 minute
//     console.log('time', expirationTime);
//     return expirationTime;
// }


