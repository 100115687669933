import { useState } from "react";
import CustomMenu from "../CustomMenu";
import CustomButton from "../../atoms/CustomButton";
import NestedCustomMenu from "../CustomMenu/NestedCustomMenu";

const ButtonDropDown = ({ menu, buttonTitle, type = "normal" }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <CustomButton
        text={buttonTitle}
        type="btn-primary"
        handleClick={(event) => handleStatusMenu(event)}
      />

      {type === "normal" ? (
        <CustomMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          items={menu}
        />
      ) : (
        <NestedCustomMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          items={menu}
        />
      )}
    </>
  );
};
export default ButtonDropDown;
