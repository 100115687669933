import { RouterProvider, useRoutes } from "react-router-dom";
import routes from "./routes/routes";
import "./assets/styles/styles.scss";
import { Toaster } from "react-hot-toast";
import router from "./routes/routes";

const App = () => {
  return (
    <>
      <RouterProvider router={router} />
      <Toaster />
    </>
  );
};

export default App;
