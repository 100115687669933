import React from "react";

const Counterparty = ({ fill = "white" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3_65)">
        <path
          d="M2.66667 8.66667H4.66667V10H2.66667V8.66667ZM6 10H8V8.66667H6V10ZM2.66667 12.6667H4.66667V11.3333H2.66667V12.6667ZM6 12.6667H8V11.3333H6V12.6667ZM2.66667 4.66667H4.66667V3.33333H2.66667V4.66667ZM6 4.66667H8V3.33333H6V4.66667ZM2.66667 7.33333H4.66667V6H2.66667V7.33333ZM6 7.33333H8V6H6V7.33333ZM16 5.33333V16H0V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0L8.66667 0C9.1971 0 9.70581 0.210714 10.0809 0.585786C10.456 0.960859 10.6667 1.46957 10.6667 2V3.33333H14C14.5304 3.33333 15.0391 3.54405 15.4142 3.91912C15.7893 4.29419 16 4.8029 16 5.33333ZM9.33333 2C9.33333 1.82319 9.2631 1.65362 9.13807 1.5286C9.01305 1.40357 8.84348 1.33333 8.66667 1.33333H2C1.82319 1.33333 1.65362 1.40357 1.5286 1.5286C1.40357 1.65362 1.33333 1.82319 1.33333 2V14.6667H9.33333V2ZM14.6667 5.33333C14.6667 5.15652 14.5964 4.98695 14.4714 4.86193C14.3464 4.7369 14.1768 4.66667 14 4.66667H10.6667V14.6667H14.6667V5.33333ZM12 10H13.3333V8.66667H12V10ZM12 12.6667H13.3333V11.3333H12V12.6667ZM12 7.33333H13.3333V6H12V7.33333Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3_65">
          <rect width="16" height="16" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Counterparty;
