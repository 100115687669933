import React, { useMemo } from 'react'
import TabStrip from '../../../atoms/tabstrip'
import { useState } from 'react';
import { Box } from '@mui/material';
import ChangePassword from './change-password';
import EditProfile from './edit';

const Edit = () => {
    const [activeTab, setActiveTab] = useState("Edit Profile");

    const data = useMemo(() => [
        {
            id : 1,
            label : 'Edit Profile',
            view: <EditProfile/>
        },      {
            id : 2,
            label : 'Change Password',
            view: <ChangePassword/>
        },
    ],[])

    const _getComponent = (tabs) => {
        const item = data.find((item) => item.label === tabs);
        return item ? item.view : null;
      };
    
      const onTabChange = (event, value) => {
        setActiveTab(value);
      };
  return (
    <>
       <TabStrip activeTab={activeTab} onTabChange={onTabChange} data={data} />
      <Box sx={{ width: "100%" }}>
        <Box sx={{p : 2}}>{_getComponent(activeTab)}</Box>
      </Box>
    </>
  )
}

export default Edit