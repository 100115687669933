import React from "react";
import ArrowDown from "../../icons/ArrowDown";
import "./styles.scss";

const PrimitiveDropdown = ({ selected, options, onSelect, className = "" }) => {
  return (
    <div className={`primitive-dropdown ${className}`}>
      <div
        className="dropdown-box"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {selected}
        <ArrowDown />
      </div>
      <ul className="dropdown-menu">
        {options?.map((option) => (
          <li key={option}>
            <button className="dropdown-item" onClick={() => onSelect(option)}>
              <span>{option}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PrimitiveDropdown;