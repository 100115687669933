import { Await, useLocation, useParams } from "react-router-dom";
import MyDropdown from "../../../../../atoms/MyDropdown";
import { useEffect, useState } from "react";
import { getData } from "../../../../../../services";
import { useCookies } from "react-cookie";
import ExcelToTablesFromURL from "./ExcelParser";
import { extractSheetNamesFromUrl } from "../../../../../../helpers/extractTabNames";


const AnalysisView = ({ id }) => {
  // const { date, id } = useParams();
  const [cookies] = useCookies(["t", "roleGroup"]);
  const [fileData, setFileData] = useState();

  const getFundingData = async () => {
    try {
      const res = await getData({
        endpoint: "analysis/getLatestFinancialFile",
        token: cookies.t,
        params: {
          id: id,
        },
      });

      setFileData(res?.data?.[0]?.latestFinancialAnalysis);
    } catch (err) {
      console.log("errr", err);
    }
  };
  useEffect(() => {
    if (id) {
      getFundingData();
    }
  }, []);


  return (
    <>
      <ClientFilesTable fileData={fileData} />
    </>
  );
};

const ClientFilesTable = ({ fileData }) => {
  const [data, setData] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);


  const getFileName = async (url) => {
    const res = await extractSheetNamesFromUrl(url);
    return res;
  };

  useEffect(() => {
    const fetchData = async () => {
      const link = fileData?.s3UploadedLink;
      const mimetype = fileData?.mimetype;
      let tabNames;

      // Check for excel file types
      if (
        mimetype &&
        (mimetype ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          mimetype === "application/vnd.ms-excel")
      ) {
        const fileName = await getFileName(link);

        if (fileName) {
          tabNames = fileName;
        }
        // }

        // Add the tabNames array to the fileData object
        fileData.tabNames = tabNames;
      }


      setData(fileData);
    };
    if (fileData) {
      fetchData();
    }
  }, [fileData]);


  return (
    <>
      <MyDropdown
        placeholder={"Select Sheet Name"}
        data={data?.tabNames || []}
        width="32.436%"
        onChange={(option) => setSelectedTab(option)}
        selectedValue={selectedTab || data?.tabNames?.[0]?.value }
      />

      <ExcelToTablesFromURL
        fileUrl={data?.s3UploadedLink || null}
        tabName={selectedTab || data?.tabNames?.[0]?.value}
      />
    </>
  );
};

export default AnalysisView;
