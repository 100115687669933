import moment from "moment";
import style from "./index.module.scss";
import ViewMore from "../../../../../atoms/ViewMore";

const CounterPartyBasicInfo = ({ clientData }) => {
  console.log("clientData", clientData);
  return (
    <>
      <div className={style.fundedViewleftWrap}>
        <table class="table">
          <thead>
            <tr>
              <th
                style={{
                  borderLeft: "1px solid #DEE3E7",
                  borderTop: "1px solid #DEE3E7",
                  borderRight: "1px solid #DEE3E7",
                  padding: "8px",
                }}
                scope="col-2"
                colSpan="2"
              >
                Counterparty Information
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                  width: "30%",
                }}
              >
                Name:
              </td>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                {clientData?.counter_type === "Client"
                  ? clientData?.counter_name
                  : clientData?.client_name}
              </td>
            </tr>
            <tr>
              <td
                c
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                Address:
              </td>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                {clientData?.address ? clientData?.address : "N/A"}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                TaxID:
              </td>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                {clientData?.tax_id ? clientData?.tax_id : "N/A"}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                Type:
              </td>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                {clientData?.counter_type}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                Verified:
              </td>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                {clientData?.verified ? clientData?.verified : "N/A"}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                Notes:
              </td>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                {clientData?.notes ? (
                  <ViewMore data={clientData?.notes} />
                ) : (
                  "N/A"
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Credit Information</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                Counterparty Type:
              </td>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                {clientData?.counter_type}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                Internal Credit Limit:
              </td>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                {clientData?.credit_limit ? clientData?.credit_limit : "N/A"}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                Credit Used:
              </td>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                {clientData?.credit_limit
                  ? parseFloat(clientData?.credit_limit)
                  : "N/A"}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                Credit Ramaining:
              </td>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                {clientData?.credit_limit
                  ? parseFloat(clientData?.credit_limit)
                  : "N/A"}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                Sector:
              </td>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                {clientData?.gics_sector ? clientData?.gics_sector : "N/A"}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                Industry:
              </td>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                {clientData?.gics_industry ? clientData?.gics_industry : "N/A"}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                Geography:
              </td>
              <td
                style={{
                  border: "1px solid #DEE3E7",
                  padding: "8px",
                }}
              >
                {clientData?.geography ? clientData?.geography : "N/A"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
export default CounterPartyBasicInfo;
