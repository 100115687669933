const EyeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_93_273)">
        <path
          d="M19.3924 7.84905C18.0999 5.74405 15.1599 2.21238 9.99989 2.21238C4.83989 2.21238 1.89989 5.74405 0.60739 7.84905C0.20783 8.49531 -0.0038147 9.24009 -0.0038147 9.99988C-0.0038147 10.7597 0.20783 11.5045 0.60739 12.1507C1.89989 14.2557 4.83989 17.7874 9.99989 17.7874C15.1599 17.7874 18.0999 14.2557 19.3924 12.1507C19.792 11.5045 20.0036 10.7597 20.0036 9.99988C20.0036 9.24009 19.792 8.49531 19.3924 7.84905ZM17.9716 11.2782C16.8616 13.0832 14.3491 16.1207 9.99989 16.1207C5.65072 16.1207 3.13822 13.0832 2.02822 11.2782C1.79084 10.8941 1.6651 10.4514 1.6651 9.99988C1.6651 9.54832 1.79084 9.10568 2.02822 8.72155C3.13822 6.91655 5.65072 3.87905 9.99989 3.87905C14.3491 3.87905 16.8616 6.91322 17.9716 8.72155C18.2089 9.10568 18.3347 9.54832 18.3347 9.99988C18.3347 10.4514 18.2089 10.8941 17.9716 11.2782Z"
          fill="#3B424A"
        />
        <path
          d="M9.99983 5.8332C9.17574 5.8332 8.37016 6.07757 7.68495 6.53541C6.99975 6.99325 6.4657 7.64399 6.15033 8.40535C5.83497 9.16671 5.75245 10.0045 5.91322 10.8127C6.074 11.621 6.47083 12.3634 7.05355 12.9461C7.63627 13.5289 8.3787 13.9257 9.18695 14.0865C9.99521 14.2472 10.833 14.1647 11.5943 13.8494C12.3557 13.534 13.0064 12.9999 13.4643 12.3147C13.9221 11.6295 14.1665 10.824 14.1665 9.99987C14.1652 8.8952 13.7258 7.83616 12.9446 7.05505C12.1635 6.27393 11.1045 5.83452 9.99983 5.8332ZM9.99983 12.4999C9.50538 12.4999 9.02203 12.3532 8.6109 12.0785C8.19978 11.8038 7.87935 11.4134 7.69013 10.9566C7.50091 10.4998 7.4514 9.99709 7.54787 9.51214C7.64433 9.02719 7.88243 8.58173 8.23206 8.2321C8.58169 7.88247 9.02715 7.64436 9.5121 7.5479C9.99706 7.45144 10.4997 7.50095 10.9565 7.69017C11.4134 7.87939 11.8038 8.19982 12.0785 8.61094C12.3532 9.02206 12.4998 9.50541 12.4998 9.99987C12.4998 10.6629 12.2364 11.2988 11.7676 11.7676C11.2988 12.2365 10.6629 12.4999 9.99983 12.4999Z"
          fill="#3B424A"
        />
      </g>
      <defs>
        <clipPath id="clip0_93_273">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default EyeIcon;
