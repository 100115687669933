import { useCookies } from "react-cookie";
import CloseButton from "./CloseButton";
import CustomButton from "../../atoms/CustomButton";
import "./styles.scss";

const Modal = ({ width = "400px", onClose, isActive }) => {
  const [, , removeCookies] = useCookies([
    "cid",
    "t",
    "uid",
    "un",
    "role",
    "roleGroup",
  ]);
  const cookieNames = ["cid", "t", "uid", "un", "role", "roleGroup"];

  const handleLogout = () => {
    cookieNames.forEach((cookieName) => {
      removeCookies(cookieName, { path: "/" });
    });
  };
  const styles = {
    width: width,
  };
  return (
    <div className={`modal ${isActive ? "active" : ""}`}>
      <div className="modal-content" style={styles}>
        <div className="modal-body p-4">
          <div className="d-flex w-100 justify-between">
            <p className="modal-heading mb-2">Logout</p>
            <CloseButton onClose={() => onClose()} />
          </div>
          <p className="modal-description">
            Are you sure you want to Logout.
          </p>
          <div className="d-flex w-100 mt-3 gap-2">
            <CustomButton
              text="Cancel"
              handleClick={onClose}
              style={{ flex: "1 0 45%" }}
              iconRequired={false}
            />
            {/* Adjust the style for the "Confirm" button */}
            <button
              className="btn-lg btn-primary mt-0"
              onClick={() => handleLogout()}
              style={{ flex: "1 0 45%", marginLeft: "10px" }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
