import { useEffect, useRef, useState } from "react";
import FilterIcon from "../../icons/FilterIcon";
import CustomButton from "../../atoms/CustomButton";
import Close from "../../icons/Close";
import "./filterComponent.scss";
import CheckboxWrapper from "../../atoms/CheckBoxWrapper/Checkbox";
import { DateSelector } from "../../atoms/MonthYearSelector";

const FilterComponent = ({
  onFilterChange,
  filterData: propsFilterData,
  dateRangeNames,
  isDateRange = false,
  headingMobile,
  headingText = "Filter",
}) => {
  const containerRef = useRef(null);
  const dropdownRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [updatedFilterData, setUpdatedFilterData] = useState({});

  const filterData = propsFilterData || {};

  const updated = dateRangeNames?.reduce((acc, name, index) => {
    acc[name] = [`startDate${index}`, `endDate${index}`];
    return acc;
  }, {});

  useEffect(() => {
    const updatedFilterData = isDateRange
      ? { ...propsFilterData, ...updated }
      : propsFilterData;
    setUpdatedFilterData(updatedFilterData);
  }, [propsFilterData]);

  // const updatedFilterData = isDateRange
  //   ? { ...tempupdatedFilterData, "Date Range": ["startDate", "endDate"] }
  //   : propsFilterData;

  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedKey, setSelectedKey] = useState();
  const [selectedOptionsMap, setSelectedOptionsMap] = useState({});

  useEffect(() => {
    const keys = Object.keys(updatedFilterData);
    setSelectedKey(keys[0]);
  }, [updatedFilterData]);

  const toggleFilterBox = () => {
    setIsOpen(!isOpen);
  };

  const handleKeyClick = (key) => {
    setSelectedKey(key);
  };

  const handleOptionChange = (option) => {
    setSelectedOptionsMap((prevOptionsMap) => {
      const updatedOptionsMap = { ...prevOptionsMap };

      if (!updatedOptionsMap[selectedKey]) {
        updatedOptionsMap[selectedKey] = [];
      }

      if (updatedOptionsMap[selectedKey].includes(option)) {
        updatedOptionsMap[selectedKey] = updatedOptionsMap[selectedKey].filter(
          (prevOption) => prevOption !== option,
        );
      } else {
        updatedOptionsMap[selectedKey] = [
          ...updatedOptionsMap[selectedKey],
          option,
        ];
      }

      return updatedOptionsMap;
    });
  };

  useEffect(() => {
    if (!isDateRange) {
      const handleClickOutside = (event) => {
        if (
          isOpen &&
          containerRef.current &&
          !containerRef.current.contains(event.target) &&
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setIsOpen(false);
          // handleReset();
        }
      };

      document.addEventListener("click", handleClickOutside);

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }
  }, [isOpen]);

  const handleApply = () => {
    const filterValues = {
      ...selectedOptionsMap,
      ...dateRange,
    };

    onFilterChange(filterValues);
    setIsOpen(false);
  };

  const handleReset = () => {
    setSelectedOptionsMap({});
    setDateRange({ startDate: null, endDate: null });
    setIsOpen(false);
    onFilterChange({});
  };

  const handleChecked = (value) => {
    const val =
      selectedOptionsMap[selectedKey] &&
      selectedOptionsMap[selectedKey].includes(value);

    return val || false;
  };

  const filteredOptions = updatedFilterData[selectedKey]?.filter((option) =>
    option.label?.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <div
      className={
        headingMobile
          ? "custom-filter-container filter-mobile-container"
          : "custom-filter-container"
      }
      ref={containerRef}
    >
      <CustomButton
        handleClick={toggleFilterBox}
        iconRequired
        forMobile
        icon={<FilterIcon />}
        size="1"
        type="btn-outline-dark"
        text={headingMobile ? "" : headingText}
        classes={`filter-contain-btn ${
          headingMobile ? "filter-mobile-container-btn" : ""
        }
        ${
          (selectedOptionsMap &&
            Object.values(selectedOptionsMap).some(
              (value) => value.length > 0,
            )) ||
          (isDateRange &&
            Object.values(dateRange).some((value) => value !== null))
            ? "active-filter"
            : ""
        }
      `}
        isActive={
          (selectedOptionsMap &&
            Object.values(selectedOptionsMap).some(
              (value) => value.length > 0,
            )) ||
          (isDateRange &&
            Object.values(dateRange).some((value) => value !== null))
        }
        style={{ minWidth: "128.85px" }}
      />

      {isOpen && (
        <div className="filter-box" ref={dropdownRef}>
          <div className="filter-header">
            <p className="header">Filters</p>

            <span
              className="header"
              style={{ cursor: "pointer" }}
              onClick={() => {
                toggleFilterBox();
                handleReset();
              }}
            >
              <i className="d-flex">
                <Close />
              </i>
            </span>
          </div>

          <div className="filter-data-box">
            <div className="filter-key-box">
              {Object.keys(updatedFilterData).map((key) => (
                <div
                  key={key}
                  onClick={() => handleKeyClick(key)}
                  className={`filter-key ${
                    selectedKey === key ? "active-key" : ""
                  }`}
                >
                  {key}
                  {(selectedOptionsMap?.[key]?.length > 0 ||
                    (isDateRange &&
                      (dateRange[`startDate${dateRangeNames.indexOf(key)}`] ||
                        dateRange[
                          `endDate${dateRangeNames.indexOf(key)}`
                        ]))) && <div className="dot-primary"> </div>}
                </div>
              ))}
            </div>

            <div className="filters-column">
              {dateRangeNames?.includes(selectedKey) ? (
                <div>
                  <div style={{ position: "relative" }}>
                    <DateSelector
                      placeholder="Start Date"
                      onChange={(date) =>
                        setDateRange((prev) => ({
                          ...prev,
                          [`startDate${dateRangeNames?.indexOf(selectedKey)}`]:
                            date,
                        }))
                      }
                      selectedDate={
                        dateRange[
                          `startDate${dateRangeNames?.indexOf(selectedKey)}`
                        ]
                      }
                    />
                  </div>

                  <div className="mt-3">
                    <div style={{ position: "relative" }}>
                      <DateSelector
                        placeholder="End Date"
                        onChange={(date) =>
                          setDateRange((prev) => ({
                            ...prev,
                            [`endDate${dateRangeNames.indexOf(selectedKey)}`]:
                              date,
                          }))
                        }
                        selectedDate={
                          dateRange[
                            `endDate${dateRangeNames.indexOf(selectedKey)}`
                          ]
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <input
                    key={selectedKey}
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    style={{
                      height: "35px",
                      marginBottom: "15px",
                      width: "100%",
                      padding: "5px 10px",
                    }}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  {filteredOptions?.map((option) => (
                    <div key={option.value} className="mb-1">
                      <label
                        className="checkbox_container mb-3"
                        htmlFor={option.value}
                      >
                        <label htmlFor="">{option.label}</label>
                        <CheckboxWrapper
                          checked={handleChecked(option.value)}
                          onChange={() => handleOptionChange(option.value)}
                        />
                      </label>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <hr className="my-0" />
          <div className="d-flex gap-3 p-3">
            <button
              className="btn btn-outline-dark mt-0 w-50"
              onClick={handleReset}
            >
              Reset
            </button>
            <button className="btn btn-primary mt-0 w-50" onClick={handleApply}>
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterComponent;
