import { useNavigate } from "react-router-dom";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import style from "./index.module.scss";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import { memo, useCallback, useEffect, useState } from "react";
import NoContentCard from "../../../atoms/NoContentCard";
import { deleteData, getData } from "../../../../services";
import { useCookies } from "react-cookie";
import Pagination from "../../../atoms/Pagination";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import Modal from "../../../atoms/modals/DeleteModal";
import CustomMenu from "../../../molecules/CustomMenu";
import HorizontalMenu from "../../../icons/HorizontalMenu";
import ButtonDropDown from "../../../molecules/ButtonDropDown";
import moment from "moment";
import Delete from "../../../icons/Delete";
import EditBlue from "../../../icons/EditBlue";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import { notestatusData, NoteType } from "../../../../utils/staticData";
import View from "../../../icons/View";
import { useSelector } from "react-redux";
import { downloadReport } from "../../../../services/downloadService";
import CustomButton from "../../../atoms/CustomButton";
import Upload from "../../../icons/Upload";

const General = [{ label: "General", value: "general" }];
const ClientQnA = [
  { label: "Existing Client", value: "clientQ&A" },
  { label: "Prospective Client", value: "prospectiveQ&A" },
];
const ClientMonitoring = [
  { label: "Existing Client", value: "clientMonitoring" },
  { label: "Internal Client", value: "clientInternalMonitoring" },
];
const Prospective = [
  { label: "Prospective Client Onboarding", value: "prospectiveOnboarding" },
];
const updates = [
  { label: "Platform Update", value: "platformUpdate" },
  { label: "Broker Update", value: "brokerUpdate" },
];

const Notes = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Notes;
  const [notesData, setNotesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [searchData, setSearchData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [filters, setFilters] = useState({});
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [loader, setLoader] = useState(true);

  const filtersData = {
    General: General,
    "Client Q&A": ClientQnA,
    "Client Monitoring": ClientMonitoring,
    "Prospective Client Onboarding": Prospective,
    Updates: updates,
    Status: notestatusData,
  };

  const GetInvestorData = async () => {
    setLoader(true);
    console.log("fil", filters);
    const noteTypeFilters = [
      ...Object.entries(filters)
        .filter(([key]) => key !== "Status") // Exclude "Status"
        .flatMap(([, value]) => value)
        .filter((item) => item != null), // Flatten the remaining values
    ];

    console.log("note", noteTypeFilters);

    try {
      const res = await getData({
        endpoint: "notes/getAllNotes",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          noteType: noteTypeFilters,
          status: filters?.["Status"],
          // group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
      });
      setNotesData(res?.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    GetInvestorData();
  }, [currentPage, itemsPerPage, searchData, filters]);

  const CustomNoRowsOverlay = memo(() => {
    return (
      !loader && (
        <>
          <NoContentCard
            title="No Notes created yet!"
            tag="Create Notes"
            type="Notes"
            classes="d-flex align-items-center"
            {...(roleData?.["add/edit"]
              ? {
                  subtitle:
                    "No Notes have been added. Click on Create Notes to create new Note.",
                }
              : {})}
          >
            {roleData?.["add/edit"] && (
              <div>
                <ButtonDropDown menu={menu} buttonTitle="Notes" />
              </div>
            )}
          </NoContentCard>
        </>
      )
    );
  });

  const handleStatusMenu2 = (event, row) => {
    setAnchorEl2(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const menu2 = [
    {
      icon: <View height="20" width="20" />,
      label: "View",
      onClick: () => {
        navigate(
          `/dashboard/notes/view/${selectedRow?.type}/${selectedRow?._id}`,
          {
            state: { edit: selectedRow?.counter_type },
          },
        );
      },
    },
    {
      icon: <EditBlue height="20" width="20" />,
      label: "Edit",
      onClick: () => {
        navigate(`/dashboard/notes/${selectedRow?.type}/${selectedRow?._id}`, {
          state: { edit: selectedRow?.counter_type },
        });
      },
    },
    {
      icon: <Delete />,
      label: "Delete",
      onClick: () => {
        setModalStateDelete({
          isModalOpen: true,
          name: selectedRow.client_name,
          modalId: selectedRow._id,
        });
        setAnchorEl2(null);
      },
    },
  ];

  const handleEdit = (data) => {
    navigate(`/dashboard/investor/add/${data?._id}`);
  };

  const menu = [
    {
      label: "General",
      onClick: () => {
        navigate(`general`);
      },
    },
    {
      label: "Client Q&A",
      children: [
        {
          label: "Existing Client",
          onClick: () => {
            navigate(`clientQ&A`);
          },
        },
        {
          label: "Prospective Client",
          onClick: () => {
            navigate(`prospectiveQ&A`);
          },
        },
      ],
    },
    {
      label: "Client Monitoring",
      children: [
        {
          label: "Existing Client",
          onClick: () => {
            navigate(`clientMonitoring`);
          },
        },
        {
          label: "Internal Client",
          onClick: () => {
            navigate(`clientInternalMonitoring`);
          },
        },
      ],
    },
    {
      label: "Prospective Client Onboarding",
      onClick: () => {
        navigate(`prospectiveOnboarding`);
      },
    },
    {
      label: "Updates",
      children: [
        {
          label: "Platform Update",
          onClick: () => {
            navigate(`platformUpdate`);
          },
        },
        {
          label: "Broker Update",
          onClick: () => {
            navigate(`brokerUpdate`);
          },
        },
      ],
    },
  ];

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getNoteType = (type) => {
    switch (type) {
      case "general":
        return "General";
      case "clientQ&A":
        return "Existing Client Q&A";
      case "clientInternalMonitoring":
        return "Internal Client Monitoring";
      case "prospectiveQ&A":
        return "Prospective Client Q&A";
      case "prospectiveOnboarding":
        return "Prospective Client Onboarding";
      case "clientMonitoring":
        return "Existing Client Monitoring";
      case "platformUpdate":
        return "Platform Update";
      case "brokerUpdate":
        return "Broker Update";
      default:
        return "Unknown Type";
    }
  };

  const columns = [
    {
      field: "client_name",
      headerName: "Client Name(CN) / Reference Name(RN)",
      flex: 2,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {[
              "clientQ&A",
              "clientInternalMonitoring",
              "clientMonitoring",
            ].includes(params?.row?.type)
              ? `${params?.row?.client_name} (CN)`
              : `${params?.row?.client_name} (RN)`}
          </div>
        );
      },
    },
    {
      field: "note_date",
      headerName: "Note Date",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {moment(params?.row?.note_date).format("DD-MM-YYYY")}
          </div>
        );
      },
    },
    {
      field: "note_type",
      headerName: "Note Type",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {getNoteType(params?.row?.type)}
          </div>
        );
      },
    },
    {
      field: "note_taker",
      headerName: "Note Taker",
      flex: 1.2,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>{params?.row?.note_taker}</div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return <div className={style.valueStyle}>{params?.row?.status}</div>;
      },
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      renderCell: (params, index) => {
        return (
          <>
            <span
              type="button"
              onClick={(event) => handleStatusMenu2(event, params.row)}
            >
              <HorizontalMenu />
            </span>

            <CustomMenu
              anchorEl={anchorEl2}
              open={Boolean(anchorEl2) && selectedRow === params.row}
              onClose={handleClose2}
              items={getMenuList()}
            />
          </>
        );
      },
    },
  ];
  const getMenuList = () => {
    if (
      roleData &&
      roleData?.["add/edit"] &&
      roleData?.view &&
      roleData?.delete
    ) {
      return menu2;
    } else if (roleData && roleData?.["add/edit"] && roleData?.view) {
      return [menu2[0], menu2[1]];
    } else if (roleData && roleData?.["add/edit"] && roleData?.delete) {
      return [menu2[1], menu2[2]];
    } else if (roleData && roleData?.view && roleData?.delete) {
      return [menu2[0], menu2[2]];
    } else if (roleData && roleData?.view) {
      return [menu2[0]];
    } else if (roleData && roleData?.delete) {
      return [menu2[2]];
    } else if (roleData && roleData?.["add/edit"]) {
      return [menu2[1]];
    } else return [];
  };
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };
  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "notes/delete",
        token: cookies.t,
        params: { id: modalStateDelete?.modalId },
      });
      if (res) {
        toast.success("Notes Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        GetInvestorData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const handleDownload = async () => {
    const noteTypeFilters = [
      ...Object.entries(filters)
        .filter(([key]) => key !== "Status") // Exclude "Status"
        .flatMap(([, value]) => value)
        .filter((item) => item != null), // Flatten the remaining values
    ];
    const res = await downloadReport({
      endpoint: "notes/export",
      token: cookies.t,
      fileName: "Notes",
      params: {
        search: searchData,
        noteType: noteTypeFilters,
        status: filters?.["Status"],
        group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
      },
    });

    if (res) {
      toast.success("Notes Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className={"headingSearchWrap"}>
        <div>
          <Heading className="HeadingStyle">Notes</Heading>
        </div>
        <div className={"searchButton"}>
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />

          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}
          <FilterComponent
            filterData={filtersData}
            onFilterChange={(filters) => {
              setFilters(filters);
            }}
          />
          {roleData && roleData?.["add/edit"] && (
            <ButtonDropDown
              menu={menu}
              buttonTitle="Create Notes"
              type="nested"
            />
          )}
        </div>
      </div>
      <CustomDatagrid
        rows={notesData?.[0]?.roleData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        loader={loader}
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {/* {notesData?.[0]?.pageDetails?.count > 10 && ( */}
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={notesData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
      {/* )} */}
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title="Notes"
        name={modalStateDelete.name}
      />
    </>
  );
};
export default Notes;
