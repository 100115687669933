import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../atoms/CustomButton";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import style from "./index.module.scss";
import Table from "./Table";
import { useCookies } from "react-cookie";
import { getData } from "../../../../services";
import TabStrip from "../../../atoms/tabstrip";
import { Box } from "@mui/material";
// import Assign from "../../../icons/Assign";
import { useSelector } from "react-redux";
// import Modal from "../../../atoms/modals/Modal";
// import ReviewView from "./View/ReviewView";

const PaymentManagement = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t", "roleGroup"]);
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Payment;
  const [activeTab, setActiveTab] = useState("Review");
  const [statusData, setStatusData] = useState();

  const [searchData, setSearchData] = useState();
  // const [assignModalState, setAssignModalState] = useState({
  //   isModalOpen: false,
  //   modalId: "",
  //   data: "",
  //   modalTitle: "",
  // });

  const getPaymentStatus = async () => {
    const res = await getData({
      endpoint: "payment/getAllPaymentStatus",
      token: cookies.t,
      params: searchData
        ? {
            search: searchData,
            group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
          }
        : {
            group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
          },
    });
    if (res) {
      setStatusData(res.data);
    }
  };

  // const handleAssigModal = () => {
  //   setAssignModalState(() => ({
  //     isModalOpen: false,
  //     modalId: "",
  //     modalTitle: "",
  //     data: "",
  //   }));
  // };

  useEffect(() => {
    (async () => {
      const res = await getData({
        endpoint: "payment/getAllPaymentStatus",
        token: cookies.t,
        params: {
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
      });
      if (res) {
        setStatusData(res.data);
      }
    })();
    getPaymentStatus();
  }, []);

  const data = useMemo(
    () => [
      {
        id: 1,
        label: "Review",
        view: (
          <Table
            type="New"
            getPaymentStatus={getPaymentStatus}
            searchData={searchData}
            roleData={roleData}
            // setAssignModalState={setAssignModalState}
            // assignModalState={assignModalState}
          />
        ),
        badge: statusData?.New || 0,
      },
      {
        id: 2,
        label: "Reserves",
        view: (
          <Table
            type="Reserves"
            getPaymentStatus={getPaymentStatus}
            searchData={searchData}
            roleData={roleData}
            // setAssignModalState={setAssignModalState}
            // assignModalState={assignModalState}
          />
        ),
        badge: statusData?.Reserves || 0,
      },
      {
        id: 3,
        label: "Pending",
        view: (
          <Table
            type="Pending"
            getPaymentStatus={getPaymentStatus}
            searchData={searchData}
            roleData={roleData}
          />
        ),
        badge: statusData?.Pending || 0,
      },
      {
        id: 4,
        label: "Assigned",
        view: (
          <Table
            type="Assigned"
            getPaymentStatus={getPaymentStatus}
            roleData={roleData}
            searchData={searchData}
          />
        ),
        badge: statusData?.Assigned || 0,
      },
    ],
    [statusData, searchData],
  );

  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };

  return (
    <>
      <div className="fixed-part">
        <div className={style.headingSearchWrap}>
          <div>
            <Heading className="HeadingStyle">Payment</Heading>
          </div>
          <div className={style.searchButton}>
            <Search
              onSearchEmit={(data) => {
                setSearchData(data);
              }}
            />
            {roleData && roleData?.["add/edit"] && (
              <CustomButton
                text="Create Payment"
                type="btn-primary"
                handleClick={() => {
                  navigate("/dashboard/payments/add");
                }}
              />
            )}
          </div>
        </div>

        <TabStrip activeTab={activeTab} onTabChange={onTabChange} data={data} />
      </div>

      <Box sx={{ width: "100%" }}>
        <Box className='tab-table'>{_getComponent(activeTab)}</Box>
      </Box>
      {/* {assignModalState?.isModalOpen && (
        <Modal
          isActive={assignModalState.isModalOpen}
          title={assignModalState.modalTitle}
          width="1560px"
          bodyStyles={{ height: "700px" }}
          onClose={handleAssigModal}
        >
          <ReviewView
            paymentInfoData={assignModalState.data}
            setAssignModalState={setAssignModalState}
            getPaymentStatus={getPaymentStatus}
          />
        </Modal>
      )} */}
    </>
  );
};
export default PaymentManagement;
