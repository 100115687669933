const LogoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="177"
      height="39"
      viewBox="0 0 177 39"
      fill="none"
    >
      <path
        d="M172.644 31.456V15.136H176.676V31.456H172.644ZM172.452 12.448V8H176.836V12.448H172.452Z"
        fill="#1B242C"
      />
      <path
        d="M153.828 31.456V8.41599H157.988V31.456H153.828ZM155.812 22.208V18.912H168.772V22.208H155.812ZM155.812 11.712V8.41599H169.54V11.712H155.812Z"
        fill="#1B242C"
      />
      <path
        d="M137.856 38.016L142.08 28.544L142.816 27.328L147.424 15.136H151.712L141.952 38.016H137.856ZM141.472 31.296L134.848 15.136H139.232L144.768 29.792L141.472 31.296Z"
        fill="#1B242C"
      />
      <path
        d="M128.519 31.456V8.41599H132.551V31.456H128.519Z"
        fill="#1B242C"
      />
      <path
        d="M117.956 31.776C116.399 31.776 115.129 31.4133 114.148 30.688C113.188 29.9413 112.591 28.9173 112.356 27.616L112.676 27.584V38.016H108.644V15.136H112.58V18.816L112.26 18.752C112.559 17.536 113.241 16.576 114.308 15.872C115.375 15.168 116.676 14.816 118.212 14.816C119.663 14.816 120.921 15.168 121.988 15.872C123.076 16.5546 123.919 17.5253 124.516 18.784C125.113 20.0426 125.412 21.5253 125.412 23.232C125.412 24.96 125.103 26.464 124.484 27.744C123.865 29.024 123.001 30.016 121.892 30.72C120.783 31.424 119.471 31.776 117.956 31.776ZM116.932 28.448C118.233 28.448 119.268 28 120.036 27.104C120.804 26.1866 121.188 24.9066 121.188 23.264C121.188 21.6213 120.793 20.352 120.004 19.456C119.236 18.56 118.201 18.112 116.9 18.112C115.62 18.112 114.585 18.5706 113.796 19.488C113.007 20.384 112.612 21.6533 112.612 23.296C112.612 24.9386 113.007 26.208 113.796 27.104C114.585 28 115.631 28.448 116.932 28.448Z"
        fill="#1B242C"
      />
      <path
        d="M98.081 31.776C96.5237 31.776 95.2544 31.4133 94.273 30.688C93.313 29.9413 92.7157 28.9173 92.481 27.616L92.801 27.584V38.016H88.769V15.136H92.705V18.816L92.385 18.752C92.6837 17.536 93.3664 16.576 94.433 15.872C95.4997 15.168 96.801 14.816 98.337 14.816C99.7877 14.816 101.046 15.168 102.113 15.872C103.201 16.5546 104.044 17.5253 104.641 18.784C105.238 20.0426 105.537 21.5253 105.537 23.232C105.537 24.96 105.228 26.464 104.609 27.744C103.99 29.024 103.126 30.016 102.017 30.72C100.908 31.424 99.5957 31.776 98.081 31.776ZM97.057 28.448C98.3584 28.448 99.393 28 100.161 27.104C100.929 26.1866 101.313 24.9066 101.313 23.264C101.313 21.6213 100.918 20.352 100.129 19.456C99.361 18.56 98.3264 18.112 97.025 18.112C95.745 18.112 94.7104 18.5706 93.921 19.488C93.1317 20.384 92.737 21.6533 92.737 23.296C92.737 24.9386 93.1317 26.208 93.921 27.104C94.7104 28 95.7557 28.448 97.057 28.448Z"
        fill="#1B242C"
      />
      <path
        d="M74.7366 31.776C73.606 31.776 72.6246 31.5413 71.7926 31.072C70.982 30.5813 70.3526 29.92 69.9046 29.088C69.478 28.256 69.2646 27.3173 69.2646 26.272V15.136H73.2966V25.44C73.2966 26.4427 73.542 27.2 74.0326 27.712C74.5447 28.224 75.2593 28.48 76.1766 28.48C77.0086 28.48 77.734 28.288 78.3526 27.904C78.9927 27.52 79.494 26.9867 79.8566 26.304C80.2406 25.6 80.4327 24.8107 80.4327 23.936L80.8486 27.84C80.3153 29.0347 79.5153 29.9947 78.4487 30.72C77.4033 31.424 76.166 31.776 74.7366 31.776ZM80.5606 31.456V27.616H80.4327V15.136H84.4966V31.456H80.5606Z"
        fill="#1B242C"
      />
      <path
        d="M57.912 31.744C55.0106 31.744 52.696 31.0827 50.968 29.76C49.2613 28.416 48.2586 26.528 47.96 24.096H51.96C52.152 25.504 52.7813 26.6027 53.848 27.392C54.9146 28.16 56.312 28.544 58.04 28.544C59.4693 28.544 60.5573 28.288 61.304 27.776C62.072 27.2427 62.456 26.5067 62.456 25.568C62.456 24.736 62.2213 24.064 61.752 23.552C61.2826 23.04 60.4933 22.624 59.384 22.304L55.224 21.056C53.112 20.416 51.5546 19.5733 50.552 18.528C49.5706 17.4827 49.08 16.1707 49.08 14.592C49.08 13.2693 49.4213 12.1173 50.104 11.136C50.7866 10.1547 51.7466 9.39733 52.984 8.86399C54.2426 8.33066 55.704 8.064 57.368 8.064C59.928 8.064 61.9653 8.69333 63.48 9.95199C65.016 11.1893 65.8906 12.8747 66.104 15.008H62.104C61.8693 13.728 61.336 12.7893 60.504 12.192C59.672 11.5733 58.552 11.264 57.144 11.264C55.9066 11.264 54.9253 11.5093 54.2 12C53.4746 12.4907 53.112 13.1627 53.112 14.016C53.112 14.72 53.3253 15.3173 53.752 15.808C54.2 16.2987 55.0213 16.7253 56.216 17.088L60.44 18.368C62.552 18.9867 64.088 19.8293 65.048 20.896C66.008 21.9627 66.488 23.3173 66.488 24.96C66.488 27.136 65.7306 28.8107 64.216 29.984C62.7226 31.1573 60.6213 31.744 57.912 31.744Z"
        fill="#1B242C"
      />
      <path
        d="M3 14L16.5 3L30 14"
        stroke="#146EF5"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 32L16.5 21L30 32"
        stroke="#146EF5"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default LogoIcon;
