import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getData } from "../../../../../services";
import { useCookies } from "react-cookie";
import { Heading } from "../../../../atoms/Heading";
import style from "./index.module.scss";
import { getPandaData } from "../../../../../services/pandaDocs";
import { PDFRenderer } from "react-doc-viewer";
import FileViewer from "react-file-viewer";

const ToolsView = () => {
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [documentData, setDocumentData] = useState({});
  const [pdfData, setPdfData] = useState(null);

  const getToolsView = async () => {
    try {
      const res = await getData({
        endpoint: "documents/getDocumentsById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setDocumentData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (documentData?.pandaDocs) {
      getScheduleDocuments();
    }
    if (pdfData) {
      URL.revokeObjectURL(pdfData); // Clean up the URL when the component unmounts
    }
  }, [documentData?.pandaDocs]);

  const getScheduleDocuments = async () => {
    try {
      // setIsLoading(true);
      const url = `https://api.pandadoc.com/public/v1/documents/${documentData?.pandaDocs}/download`;

      const response = await getPandaData({
        endpoint: url,
        params: {},
        token: "0752d8cf5fef4dfefa1c9ed82eef75295fda72ec",
      });
      const blob = new Blob([response?.data], { type: "application/pdf" });
      setPdfData(URL.createObjectURL(blob));
      // setIsLoading(false);
    } catch (err) {
      console.log("error documents", err);
    }
  };

  useEffect(() => {
    if (id) {
      getToolsView();
    }
  }, [id]);

  return (
    <>
      <Heading className="HeadingStyle">
        {documentData?.documentSubType}
      </Heading>
      {pdfData ? (
        <div className={style.pdfSizeWrap}>
          {/* {pdfData && (
            <FileViewer
              fileType="pdf"
              filePath={pdfData}
              pluginRenderers={PDFRenderer}
            />
          )} */}
          {pdfData && (
            <iframe
              src={pdfData}
              title="pdfData"
              width={"100%"}
              height={"100%"}
            />
          )}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};
export default ToolsView;
