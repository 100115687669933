import { Box, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import "./styles.scss";

const MultipleUserSelectComponent = ({
  title,
  subtitle,
  data,
  returnValue = "emp_id",
  onSelect,
  selectAllLabel = "",
  selectedEmployeeIds = [],
  labelKey = "name",
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedEmployeeNames, setSelectedEmployeeNames] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    const filtered = data
      ? data.filter((item) => {
          return item[labelKey]
            ?.toLowerCase()
            ?.includes(searchTerm.toLowerCase());
        })
      : [];

    setFilteredData(filtered);
  }, [searchTerm, data, labelKey]);

  useEffect(() => {
    if (
      Array.isArray(selectedEmployeeIds) &&
      selectedEmployeeIds?.length === 0 &&
      selectedItems.length > 0
    ) {
      setSelectedItems([]);
      setSelectedEmployeeNames([]);
      setSelectAll(false);
    }
  }, [selectedEmployeeIds]);

  useEffect(() => {
    if (
      selectedItems?.length === filteredData?.length &&
      filteredData?.length > 0
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedItems, filteredData]);

  useEffect(() => {
    if (Array.isArray(selectedEmployeeIds) && selectedEmployeeIds.length > 0) {
      const selectedItems = selectedEmployeeIds;
      if (data) {
        const selectedNames = data
          ?.filter((item) => selectedEmployeeIds.includes(item.emp_id))
          .map((item) => item.name);

        setSelectedItems(selectedItems);
        setSelectedEmployeeNames(selectedNames);
      }
    }
  }, [selectedEmployeeIds]);

  const toggleDropdown = () => {
    setMenuOpen(!menuOpen);
  };

  const handleItemClick = (item) => {
    const itemIndex = selectedItems.findIndex((id) => id === item[returnValue]);
    if (itemIndex !== -1) {
      const updatedSelectedItems = [...selectedItems];
      const updatedSelectedNames = [...selectedEmployeeNames];
      updatedSelectedItems.splice(itemIndex, 1);
      updatedSelectedNames.splice(itemIndex, 1);
      setSelectedItems(updatedSelectedItems);
      setSelectedEmployeeNames(updatedSelectedNames);
    } else {
      const updatedSelectedItems = [...selectedItems, item[returnValue]];
      const updatedSelectedNames = [...selectedEmployeeNames, item[labelKey]];
      setSelectedItems(updatedSelectedItems);
      setSelectedEmployeeNames(updatedSelectedNames);
    }
  };

  useEffect(() => {
    onSelect(selectedItems);
  }, [selectedItems, onSelect]);

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
    if (!selectAll) setSelectAll(false);
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      const empIds = filteredData.map((item) => item[returnValue]);
      const empNames = filteredData.map((item) => item[labelKey]);
      setSelectedItems(empIds);
      setSelectedEmployeeNames(empNames);
    } else {
      setSelectedItems([]);
      setSelectedEmployeeNames([]);
    }
    setSelectAll(!selectAll);
  };

  const isItemSelected = (item) => {
    return selectedItems.includes(item[returnValue]);
  };

  const getSelectedNames = () => {
    if (
      (selectAll || selectedItems.length === filteredData.length) &&
      !searchTerm
    ) {
      return selectAllLabel || "All Employees";
    } else if (selectAll && searchTerm)
      return selectAllLabel || "All Employees";
    else if (selectedItems.length > 0 && !selectAll) {
      return selectedEmployeeNames.join(", ");
    } else {
      return "";
    }
  };

  const tagsColors = {
    Supplier: "#C8E1FE",
    Obligor: "#FEE2C8",
    Client: "#EDC8FE",
  };

  const isLabelUp = (menuOpen && selectedItems.length > 0) || selectedItems?.length > 0;

  return (
    <div
      ref={dropdownRef}
      className={`user-select-container ${menuOpen ? "open" : ""}`}
    >
      <div
        className={`form-floating ${isLabelUp ? "label-up" : ""}`}
        onClick={toggleDropdown}
      >
        <input
          type="text"
          className="form-control"
          id={title}
          placeholder={`Search ${subtitle}`}
          value={getSelectedNames()}
          onChange={handleSearchInputChange}
          readOnly={true}
          aria-label={title}
          role="combobox"
          style={{
            maxHeight: "46px",
            minHeight: "initial",
            borderRadius: "4px",
          }}
        />
        <label htmlFor={title}>{title || "Employee"}</label>
      </div>
      {menuOpen && (
        <div className="user-select-dropdown-menu">
          <ul className="user-select-dropdown-list">
            <Box
              display="flex"
              backgroundColor="#F4F5FA"
              borderRadius="6px"
              marginX="10px"
              height="40px"
              marginBottom="10px"
            >
              <IconButton type="button" sx={{ p: 1 }}>
                {/* Uncomment the search icon if needed */}
                {/* <SearchIcon /> */}
              </IconButton>
              <input
                type="text"
                className="p-2"
                style={{
                  border: "none",
                  outline: "none",
                  background: "transparent",
                  width: "100%",
                }}
                id={title}
                placeholder={`Search`}
                autoFocus={true}
                value={searchTerm}
                autoComplete="off"
                onChange={handleSearchInputChange}
              />
            </Box>
            {searchTerm === "" && filteredData.length !== 0 && (
              <li
                className="user-select-dropdown-listItem d-flex align-items-center"
                onClick={handleSelectAll}
              >
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
                <div className="d-flex justify-content-between w-100">
                  <div className="d-flex align-items-center ms-3">
                    <span>{selectAllLabel || "All Employees"}</span>
                  </div>
                </div>
              </li>
            )}
            {filteredData.length === 0 && (
              <li className="user-select-dropdown-listItem d-flex align-items-center">
                <span className="ms-3">Not Found</span>
              </li>
            )}
            {filteredData.map((item, index) => (
              <li
                key={index}
                className="user-select-dropdown-listItem d-flex align-items-center"
                role="option"
                aria-selected={isItemSelected(item)}
                tabIndex={0}
                onClick={() => handleItemClick(item)}
              >
                <input
                  type="checkbox"
                  checked={isItemSelected(item)}
                  onChange={() => handleItemClick(item)}
                />
                <div className="d-flex justify-content-between w-100">
                  <div className="d-flex align-items-center ms-3">
                    <span>{item[labelKey]}</span>
                  </div>
                  <div
                    className="tag-couter"
                    style={{ backgroundColor: tagsColors?.[item?.tag] }}
                  >
                    {item.tag}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

MultipleUserSelectComponent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  returnValue: PropTypes.string,
  onSelect: PropTypes.func,
  selectAllLabel: PropTypes.string,
  selectedEmployeeIds: PropTypes.arrayOf(PropTypes.string),
  labelKey: PropTypes.string,
};

export default MultipleUserSelectComponent;
