import React from "react";
import { useNavigate } from "react-router-dom";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import style from "./index.module.scss";
import { memo, useCallback, useEffect, useState } from "react";
import NoContentCard from "../../../atoms/NoContentCard";
import { useCookies } from "react-cookie";
import Pagination from "../../../atoms/Pagination";
import CustomButton from "../../../atoms/CustomButton";
import moment from "moment/moment";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import {
  counterPartyType,
  GICSectorData,
  statusData,
  transactionType,
} from "../../../../utils/staticData";
import Upload from "../../../icons/Upload";
import { downloadReport } from "../../../../services/downloadService";
import { useSelector } from "react-redux";
import { getData } from "../../../../services";
import { formatNumberInternational } from "../../../../helpers/capatalizeLetters";

const ReportsDue = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Counterparties;
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [portfolioData, setPortfolioData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loader, setLoader] = useState(true);
  const getFundingData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "funding/getAllFundings",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          status: ["Funded"],
          transaction_type: filters?.["Transaction Type"],
          dueStartDate: filters?.startDate0,
          dueEndDate: filters?.endDate0,
          fundingStartDate: filters?.startDate1,
          fundingEndDate: filters?.endDate1,
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
      });
      setPortfolioData(res?.data);
    } catch (err) {
      console.log("errr", err);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    getFundingData();
  }, [itemsPerPage, currentPage, searchData, filters]);

  const columns = [
    {
      field: "client_name",
      headerName: "Client",
      flex: 1,
    },
    {
      field: "obligor_name",
      headerName: "Obligor",
      flex: 1,
    },
    {
      field: "funder",
      headerName: "Funder",
      flex: 1,
    },
    {
      field: "Due Date",
      headerName: "Due Date",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.due_date).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "Client Reference",
      headerName: "Client Reference",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {params?.row?.transaction_type !== "Note" &&
            params?.row?.transaction_type !== "Revolver" &&
            params?.row?.transaction_type !== "Client"
              ? params?.row?.invoice_number
              : params?.row?.referance}
          </div>
        );
      },
    },
    {
      field: "Currency",
      headerName: "Currency",
      flex: 1,
      renderCell: (params) => {
        return <div className={style.valueStyle}>{params?.row?.currency}</div>;
      },
    },
    {
      field: "cost_value",
      headerName: "Cost",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {formatNumberInternational(params?.row?.cost_value)}
          </div>
        );
      },
    },
    {
      field: "face_value",
      headerName: "Face value",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <div className={style.valueStyle}>
            {formatNumberInternational(params?.row?.face_value)}
          </div>
        );
      },
    },
    {
      field: "Funding Date",
      headerName: "Funding Date",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "transaction_type",
      headerName: "Trans Type",
      flex: 1,
      sortable: true,
    },
  ];

  const CustomNoRowsOverlay = memo(() => {
    return (
      !loader && (
        <>
          <NoContentCard
            title="No Due created yet!"
            tag="Add Counterparties"
            type="Counterparty"
            classes="d-flex align-items-center"
          />
        </>
      )
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const filtersData = {
    "Transaction Type": transactionType,
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "funding/export",
      token: cookies.t,
      fileName: "Due",
      params: {
        search: searchData,
        status: ["Funded"],
        transactionType: filters?.["Transaction Type"],
        dueStartDate: filters?.startDate0,
        dueEndDate: filters?.endDate0,
        fundingStartDate: filters?.startDate1,
        fundingEndDate: filters?.endDate1,
        group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
      },
    });

    if (res) {
      toast.success("Portfolio Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Due</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            placeholder="Search by name"
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />

          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}
          <FilterComponent
            filterData={filtersData}
            isDateRange
            dateRangeNames={["Due Date", "Funding Date"]}
            onFilterChange={(filters) => {
              setFilters(filters);
            }}
          />
        </div>
      </div>

      <CustomDatagrid
        rows={portfolioData?.[0]?.fundingData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        loader={loader}
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={portfolioData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  );
};

export default ReportsDue;
