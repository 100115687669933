import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const NestedCustomMenu = ({ anchorEl, open, onClose, items }) => {
  const [openItems, setOpenItems] = React.useState({}); // To track which items are expanded

  const handleToggle = (index) => {
    setOpenItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const renderMenuItem = (item, index) => {
    const hasChildren = item.children?.length > 0;

    return (
      <React.Fragment key={index}>
        <MenuItem
          onClick={() => (hasChildren ? handleToggle(index) : item?.onClick())}
          sx={{
            "&:hover": {
              backgroundColor: "#F5F7F9", // Change to desired hover background color
            },
            color: "#1B242C",
            fontSize: "14px",
            fontWeight: "500",
            height: "44px",
          }}
        >
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="d-flex align-items-center gap-2">
              <i>{item?.icon}</i> <span>{item?.label}</span>
            </div>
            {hasChildren ? (
              openItems[index] ? <ExpandLess /> : <ExpandMore />
            ) : null}
          </div>
        </MenuItem>
        {hasChildren && (
          <Collapse in={openItems[index]} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              sx={{ pl: 4, backgroundColor: "#f9f9f9" }}
            >
              {item.children.map((child, childIndex) =>
                renderMenuItem(child, `${index}-${childIndex}`)
              )}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    );
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          border: "1px solid #DEE3E7",
          boxShadow: "none",
          minWidth: "200px",
          marginTop: "8px",
          marginRight: "50px",
          borderRadius: "10px",
          padding: 0,
          "& .MuiList-root": {
            padding: "0px !important",
          },
        },
      }}
      PopperProps={{
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              altBoundary: true,
              tether: true,
            },
          },
        ],
      }}
      keepMounted
    >
      <List component="nav" disablePadding>
        {items?.map((item, index) => renderMenuItem(item, index))}
      </List>
    </Menu>
  );
};

export default NestedCustomMenu;
