import React from "react";
import { useField } from "formik";
import "./style.scss";

const Checkbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <div>
      <label className="d-flex gap-2 mb-0">
        <input
          type="checkbox"
          {...field}
          {...props}
          className="checkbox-input"
        />
        <span>{children}</span>
      </label>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default Checkbox;
