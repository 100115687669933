export const forceTerminl = [
  { label: "Midnight", value: "Midnight" },
  { label: "After 24hrs", value: "After 24hrs" },
];

export const defaultInviteStatus = [
  { label: "Confirmed", value: "Confirmed" },
  { label: "Pending", value: "Pending" },
];

export const statusData = [
  { label: "Active", value: "true" },
  { label: "Inactive", value: "false" },
];

export const userRoles = [
  { label: "Admin", value: "Admin" },
  { label: "Manager", value: "Manager" },
  { label: "Viewer", value: "Viewer" },
];

export const statusInvites = [
  { label: "Ongoing", value: "Ongoing" },
  { label: "Completed", value: "Completed" },
];

export const businessTypes = [
  { value: "Private Limited", label: "Private Limited" },
  { value: "Public Limited", label: "Public Limited" },
  { value: "Sole Proprietorship", label: "Sole Proprietorship" },
  { value: "Individual", label: "Individual" },
  { value: "Partnership", label: "Partnership" },
  { value: "Limited Liability Company", label: "Limited Liability Company" },
  {
    value: "Limited Liability Partnership",
    label: "Limited Liability Partnership",
  },
  { value: "Corporation", label: "Corporation" },
  {
    value: "Non-Government Organization",
    label: "Non-Government Organization",
  },
];

export const modesData = [
  { label: "Employee", value: 1 },
  { label: "Business", value: 2 },
];

export const convenantFrequency = [
  { value: "At-sight", label: "At-sight" },
  { value: "Monthly", label: "Monthly" },
  { value: "Quarterly", label: "Quarterly" },
  { value: "Bi-annual", label: "Bi-annual" },
  { value: "Annual", label: "Annual" },
];

export const convenantNames = [
  { value: "Minimum Equity", label: "Minimum Equity" },
  { value: "Key Person", label: "Key Person" },
  { value: "API Bank Integration", label: "API Bank Integration" },
  { value: "API Accounting Integration", label: "API Accounting Integration" },
  {
    value: "API Banking and Accounting Integration",
    label: "API Banking and Accounting Integration",
  },
];

export const ReportingNames = [
  {
    value: "Unaudited Financial Statements",
    label: "Unaudited Financial Statements",
  },
  {
    value: "Audited Financial Statements",
    label: "Audited Financial Statements",
  },
  {
    value: "AR Aging (details and summary)",
    label: "AR Aging (details and summary)",
  },
  {
    value: "AP Aging (details and summary)",
    label: "AP Aging (details and summary)",
  },
  { value: "Bank Statements", label: "Bank Statements" },
  {
    value: "Directors and Officers Lists",
    label: "Directors and Officers Lists",
  },
  {
    value: "Personal Financial Statement of Guarantors",
    label: "Personal Financial Statement of Guarantors",
  },
  {
    value: "Tax returns or Tax Extension",
    label: "Tax returns or Tax Extension",
  },
  { value: "Compliance Certificate", label: "Compliance Certificate" },
  { value: "Inventory Activity Report", label: "Inventory Activity Report" },
  { value: "Inventory Report", label: "Inventory Report" },
  { value: "Borrowing Base", label: "Borrowing Base" },
  { value: "HMRC Statement Balances", label: "HMRC Statement Balances" },
  { value: "Projection model", label: "Projection model" },
  { value: "AR Collection Report", label: "AR Collection Report" },
  {
    value: "Notification Regarding Ownership or Equity Capitalization",
    label: "Notification Regarding Ownership or Equity Capitalization",
  },
  { value: "Open Purchase Orders", label: "Open Purchase Orders" },
  { value: "Debt Schedule", label: "Debt Schedule" },
  { value: "WIP Report", label: "WIP Report" },
  {
    value:
      "Annual Audited Financial Statements and Signed consolidated financial statements of buyer",
    label:
      "Annual Audited Financial Statements and Signed consolidated financial statements of buyer",
  },
  { value: "List of Inventory Managers", label: "List of Inventory Managers" },
];

export const monthly_shift = [
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
];

export const reportingDays = [
  { value: 0, label: 0 },
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 30, label: 30 },
  { value: 45, label: 45 },
  { value: 60, label: 60 },
  { value: 90, label: 90 },
  { value: 120, label: 120 },
  { value: 180, label: 180 },
];
