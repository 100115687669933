import { Field, Formik } from "formik";
import SelectDropdown from "../../../../../atoms/Dropdown";
import { Heading } from "../../../../../atoms/Heading";
import style from "./index.module.scss";
import {
  BaseRateData,
  DaysData,
  Rate_TypeData,
} from "../../../../../../utils/staticData";
import InputLayout from "../../../../../atoms/InputLayout";
import CustomButton from "../../../../../atoms/CustomButton";
import { getData, patchData, postData } from "../../../../../../services";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import TextInput from "../../../../../atoms/TextInput";
import Breadcrumb from "../../../../../molecules/Breadcrumb";
import * as Yup from "yup";
import MyDropdown from "../../../../../atoms/MyDropdown";
import { isDraft } from "@reduxjs/toolkit";

const AddPrice = ({ type }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [editedData, setEditedData] = useState({});
  const [ratesList, setRatesList] = useState();
  const [filterdBaseRate, setFilteredBaseRate] = useState();
  const [copyValues, setCopyValues] = useState();

  const initialValues = {
    rate_type: editedData?.rate_type || "",
    top_rate: editedData?.top_rate || "",
    advance_rate: editedData?.advance_rate || "",
    base_rate: editedData?.base_rate || "",
    days: editedData?.days || "",
    name: editedData?.name || "",
    description: editedData?.description || "",
  };
  const validationSchema = Yup.object().shape({
    rate_type: Yup.string().required("Rate type is required"),
    top_rate: Yup.number().required("Top rate is required"),
    days: Yup.string().required("Days is required"),
    advance_rate: Yup.number()
      .typeError("Advance Rate must be a number") // Ensure it's a number
      .required("Advance Rate is required") // Required field
      .min(0, "Advance Rate must be at least 0") // Ensure it's not negative
      .max(1, "Advance Rate must be less than 1"),
  });
  const getPriceDetailsById = async () => {
    try {
      const res = await getData({
        endpoint: "/price/getPriceById",
        params: {
          id: id,
        },
        token: cookies.t,
      });
      setEditedData(res?.data);
    } catch (err) {
      console.log("error", err);
    }
  };

  const getRatesList = async () => {
    const res = await getData({
      endpoint: "rates/GetRateList",
      params: {},
      token: cookies.t,
    });
    setRatesList(res?.data);
  };
  useEffect(() => {
    if (id) {
      getPriceDetailsById();
    }
    getRatesList();
  }, [id]);

  const handleSubmit = async (values) => {
    const dataToSend = {
      advance_rate: values.advance_rate,
      base_rate: values.base_rate,
      base_rate_name: filterdBaseRate,
      days: values?.days,
      description:
        values.rate_type === "Flat"
          ? `Flat Rate: ${values?.top_rate}% Advance Rate: ${values?.advance_rate} Days: ${values?.days}`
          : `Floating: ${filterdBaseRate} + ${values?.top_rate}% ADV: ${values?.advance_rate} Days: ${values?.days}`,
      name:
        values.rate_type === "Flat"
          ? `Flat Rate: ${values.top_rate}% ADV: ${values.advance_rate}`
          : `${filterdBaseRate} + ${values?.top_rate}% ADV: ${values?.advance_rate}`,
      rate_type: values?.rate_type,
      top_rate: values?.top_rate,
    };
    if (id) {
      const res = await patchData({
        endpoint: "price/update",
        token: cookies.t,
        data: dataToSend,
        params: {
          id: id,
        },
      });
      if (res) {
        toast.success("Price Details Updated Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        navigate("/dashboard/setting/price");
      }
    } else {
      try {
        const res = await postData({
          endpoint: "price/create",
          data: dataToSend,
          token: cookies.t,
        });
        if (res) {
          toast.success("Price Details Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
          navigate("/dashboard/setting/price");
        }
      } catch (err) {
        console.log("error", err);
      }
    }
  };

  useEffect(() => {
    if (copyValues?.base_rate) {
      const data = ratesList?.filter(
        (item) => item?.value === copyValues?.base_rate,
      );
      if (data && data?.[0]) {
        setFilteredBaseRate(data[0]?.label);
      }
    }
  }, [copyValues]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        key={Math.random}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          isValid,
          dirty,
          setFieldValue,
          touched,
          errors,
          values,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldTouched,
          isSubmitting,
        }) => {
          setCopyValues(values);
          return (
            <>
              <Breadcrumb
                labels={
                  type === "edit"
                    ? ["Pricing", "Update Pricing"]
                    : ["Pricing", "Create Pricing"]
                }
                excludeLastPath={type === "edit"}
              />
              <Heading className="addHeaderStyle">
                {" "}
                {type === "edit" ? "Update" : "Create"} Price
              </Heading>
              {}
              <div className="row">
                <div className="col-3 mt-4">
                  <MyDropdown
                    placeholder="Rate Type"
                    data={Rate_TypeData}
                    onChange={(option) => {
                      setFieldValue("rate_type", option);
                    }}
                    selectedValue={values?.rate_type}
                    isError={values?.rate_type}
                    touched={touched.rate_type}
                    handleBlur={(e) => {
                      handleBlur(e);
                      setFieldTouched("rate_type", true);
                    }}
                    errors={errors.rate_type}
                    required
                  />
                </div>
                <div className="col-3 mt-4">
                  <InputLayout>
                    <TextInput
                      type="number"
                      name="top_rate"
                      customholder="Top Rate"
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      InputHeight="small"
                      onKeyDown={(e) => {
                        const invalidChars = ["e", "E", "+", "-"];
                        if (invalidChars.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      required
                    />
                  </InputLayout>
                </div>
                {values?.rate_type === "Floating" && (
                  <div className="col-3 mt-4">
                    <SelectDropdown
                      placeholder="Base Rate"
                      data={ratesList}
                      onChange={(option) => {
                        setFieldValue("base_rate", option);
                      }}
                      selectedValue={values?.base_rate}
                    />
                  </div>
                )}
                <div className="col-3 mt-4">
                  <MyDropdown
                    placeholder="Days"
                    data={DaysData}
                    onChange={(option) => {
                      setFieldValue("days", option);
                    }}
                    selectedValue={values?.days}
                    isError={values?.days}
                    touched={touched.days}
                    handleBlur={(e) => {
                      handleBlur(e);
                      setFieldTouched("days", true);
                    }}
                    errors={errors.days}
                    required
                  />
                </div>
                <div className="col-3 mt-4">
                  <InputLayout>
                    <TextInput
                      type="number"
                      name="advance_rate"
                      customholder="Advance Rate"
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      InputHeight="small"
                      autocomplete="off"
                      onKeyDown={(e) => {
                        const invalidChars = ["e", "E", "+", "-"];
                        if (invalidChars.includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      required
                    />
                  </InputLayout>
                </div>
                {/* <div className="row mt-4"> */}
                <div className="col-3 mt-4">
                  <InputLayout>
                    <TextInput
                      type="text"
                      name="name"
                      customholder="Name"
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      InputHeight="small"
                      readOnly
                      value={
                        values.rate_type === ""
                          ? ""
                          : values.rate_type === "Flat"
                          ? `Flat Rate: ${values.top_rate}% ADV: ${values.advance_rate}`
                          : `Floating Rate + ${values?.top_rate}% ADV: ${values?.advance_rate}`
                      }
                    />
                  </InputLayout>
                </div>
                <div className="col-6 mt-4">
                  <InputLayout>
                    <TextInput
                      type="text"
                      name="description"
                      customholder="Description"
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      InputHeight="small"
                      readOnly
                      value={
                        values.rate_type === ""
                          ? ""
                          : values?.rate_type === "Flat"
                          ? `Flat Rate: ${values?.top_rate}% Advance Rate: ${values?.advance_rate} Days: ${values?.days}`
                          : `Floating: ${filterdBaseRate} + ${values?.top_rate}% ADV: ${values?.advance_rate} Days: ${values?.days}`
                      }
                      info="Type, Rate, Advance Rate, Days"
                    />
                  </InputLayout>
                </div>
                {/* </div> */}
              </div>
              <div className={style.buttonWrap}>
                <div>
                  <CustomButton
                    text="Back"
                    size="btn-lg"
                    type="btn-outline-dark"
                    handleClick={() => {
                      navigate(-1);
                    }}
                  />
                </div>
                <div className={style.resetAdd}>
                  <CustomButton
                    text="Reset"
                    type="btn-outline-primary"
                    size="btn-lg"
                    handleClick={() => resetForm()}
                  />
                  <button
                    className="btn-primary btn-lg"
                    type="submit"
                    disabled={!isValid || !dirty || isSubmitting}
                    style={{ width: "100%" }}
                    onClick={() => handleSubmit(values)}
                  >
                    {id ? "Update" : "Create"}
                    {isSubmitting && (
                      <span
                        className="spinner-border spinner-border-sm ms-2 text-light"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};
export default AddPrice;
