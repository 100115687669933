import React from "react";
import { useNavigate } from "react-router-dom";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import style from "./index.module.scss";
import { memo, useCallback, useEffect, useState } from "react";
import NoContentCard from "../../../atoms/NoContentCard";
import { useCookies } from "react-cookie";
import Pagination from "../../../atoms/Pagination";
import CustomButton from "../../../atoms/CustomButton";
import moment from "moment/moment";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import {
  counterPartyType,
  GICSectorData,
  statusData,
  statusTypes,
  transactionType,
} from "../../../../utils/staticData";
import Upload from "../../../icons/Upload";
import { downloadReport } from "../../../../services/downloadService";
import { useSelector } from "react-redux";
import { getData } from "../../../../services";
import { formatNumberInternational } from "../../../../helpers/capatalizeLetters";

const ReportsPortfolio = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Counterparties;
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [portfolioData, setPortfolioData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loader, setLoader] = useState(true);

  const getFundingData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "funding/getAllFundings",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          status: filters?.["Status"]
            ? filters?.["Status"]
            : ["Funded", "Closed"],
          transaction_type: filters?.["Transaction Type"]?.map((item) =>
            item === "Factoring/AR" ? "Obligor" : item,
          ),
          dueStartDate: filters?.startDate0,
          dueEndDate: filters?.endDate0,
          fundingStartDate: filters?.startDate1,
          fundingEndDate: filters?.endDate1,
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
          report_type: "Portfolio",
        },
      });
      setPortfolioData(res?.data);
    } catch (err) {
      console.log("errr", err);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    getFundingData();
  }, [itemsPerPage, currentPage, searchData, filters]);

  console.log("filters?", filters?.["Transaction Type"]);

  const columns = [
    {
      field: "client_name",
      headerName: "Client",
      flex: 1,
    },
    {
      field: "obligor_name",
      headerName: "Obligor",
      flex: 1,
    },
    {
      field: "funder",
      headerName: "Funder",
      flex: 1,
      // renderCell: (params) => {
      //   return <div className={style.valueStyle}>{params?.row?.funder}</div>;
      // },
    },
    {
      field: "Client Reference",
      headerName: "Client Reference",
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            className={style.valueStyle}
            title={
              params?.row?.transaction_type !== "Note" &&
              params?.row?.transaction_type !== "Revolver" &&
              params?.row?.transaction_type !== "Client"
                ? params?.row?.invoice_number
                : params?.row?.referance
            }
          >
            {params?.row?.transaction_type !== "Note" &&
            params?.row?.transaction_type !== "Revolver" &&
            params?.row?.transaction_type !== "Client"
              ? params?.row?.invoice_number
              : params?.row?.referance}
          </div>
        );
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 1,
    },
    {
      field: "cost_value",
      headerName: "Cost",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <span>{formatNumberInternational(params.row?.cost_value)}</span>
          </>
        );
      },
    },
    {
      field: "face_value",
      headerName: "Face value",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{formatNumberInternational(params.row?.face_value)}</span>
          </>
        );
      },
    },
    {
      field: "Funding Date",
      headerName: "Funding Date",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "Due Date",
      headerName: "Due Date",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.due_date).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <div
            title={
              params.row.transaction_type === "Obligor"
                ? "Factoring/AR"
                : params.row.transaction_type
            }
          >
            {params.row.transaction_type === "Obligor"
              ? "Factoring/AR"
              : params.row.transaction_type}
          </div>
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>
              {params.row.status === "Funded" ? "Open" : params.row.status}
            </span>
          </>
        );
      },
    },
  ];

  const CustomNoRowsOverlay = memo(() => {
    return (
      !loader && (
        <>
          <NoContentCard
            title="No Portfolio created yet!"
            tag="Add Counterparties"
            type="Counterparty"
            classes="d-flex align-items-center"
          />
        </>
      )
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const filtersData = {
    "Transaction Type": transactionType,
    Status: [
      { label: "Funded", value: "Funded" },
      { label: "Closed", value: "Closed" },
    ],
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "funding/export",
      token: cookies.t,
      fileName: "Portfolio",
      params: {
        search: searchData,
        status: filters?.["Status"]
          ? filters?.["Status"]
          : ["Funded", "Closed"],
        transactionType: filters?.["Transaction Type"],
        dueStartDate: filters?.startDate0,
        dueEndDate: filters?.endDate0,
        fundingStartDate: filters?.startDate1,
        fundingEndDate: filters?.endDate1,
        group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        report_type: "Portfolio",
      },
    });

    if (res) {
      toast.success("Portfolio Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Portfolio</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            placeholder="Search by name"
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />

          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}
          <FilterComponent
            filterData={filtersData}
            isDateRange
            dateRangeNames={["Due Date", "Funding Date"]}
            onFilterChange={(filters) => {
              setFilters(filters);
            }}
          />
        </div>
      </div>

      <CustomDatagrid
        rows={portfolioData?.[0]?.fundingData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        loader={loader}
        onCellClick={(data) => {
          console.log("datarow -->  ", data);

          if (data?.field !== "actions") {
            navigate(`/dashboard/funding/view/${data?.row?._id}`, {
              state: {
                data: data?.row, // You can pass the entire row or other relevant data
              },
            });
          }
        }}
      />
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={portfolioData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  );
};

export default ReportsPortfolio;
