import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import topLoader from './slices/loaderSlice';
import accessData from './slices/accessSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const reducers = combineReducers({
  accessData: accessData,
  topLoader: topLoader,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

export const persistor = persistStore(store);
