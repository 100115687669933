import React from "react";
import styles from "./styles.module.scss";

const ValueLabelDisplay = ({ label, children, style }) => {
  return (
    <div className={`${styles.view_info_container} `} style={style}>
      <div className={styles.label}>{label || "--"}</div>
      <div className={styles.data}>
        <div className={styles.colon}>:</div>
        {children}
      </div>
    </div>
  );
};

export default ValueLabelDisplay;
