import React from 'react';
import './styles.scss'

const IconCircle = ({ color, size, icon, iconSize, iconColor, isSvg, svgIcon ,styles , margin = true, border}) => {
  const circleStyle = {
    backgroundColor: color,
    width: size,
    height: size,
    borderRadius: '50%', // Ensure it's a circle
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border : border,
    lineHeight : 'normal'
  };

  const iconStyle = {
    fontSize: iconSize,
    color: iconColor || '#1A1D1F',
    ...styles
  };

  return (
    <div className="icon-circle" style={circleStyle}>
      {isSvg ? (
        <span className="icon-circle-icon mt-1" style={iconStyle}>
          {svgIcon}
        </span>
      ) : (
        <span className="icon-circle-icon" style={iconStyle}>
          {icon}
        </span>
      )}
    </div>
  );
};

export default IconCircle;
