export const capitalizeLetter = (str) => {
  return str?.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const Obj2FormData = (obj, formData) => {
  if (!obj) return null;
  const createFormData = function (obj, subKeyStr = "") {
    for (const [key, value] of Object.entries(obj)) {
      const subKeyStrTrans = subKeyStr ? subKeyStr + "[" + key + "]" : key;
      if (value instanceof File) {
        const k = subKeyStr ? subKeyStr : key;
        formData.append(k, value, value.name);
        continue;
      }
      if (
        typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean"
      ) {
        if (value || typeof value === "boolean") {
          formData.append(subKeyStrTrans, value);
        }
      } else if (typeof value === "object" && value !== null) {
        createFormData(value, subKeyStrTrans);
      }
    }
  };
  createFormData(obj);
  return formData;
};
export const Obj2FormImage = (formData) => {
  if (!formData) return null;
  const createFormData = function (obj, subKeyStr = "") {
    for (const [key, value] of Object.entries(obj)) {
      const subKeyStrTrans = subKeyStr ? subKeyStr + "[" + key + "]" : key;
      if (value instanceof File) {
        const k = subKeyStr ? subKeyStr : key;
        formData.append(k, value, value.name);
        continue;
      }
      if (
        typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean"
      ) {
        if (value || typeof value === "boolean") {
          formData.append(subKeyStrTrans, value);
        }
      } else if (typeof value === "object" && value !== null) {
        createFormData(value, subKeyStrTrans);
      }
    }
  };
  createFormData(formData);
  return formData;
};
export const breakStringInMiddle = (str) => {
  if (str?.length >= 6) {
    const middleIndex = Math.floor(str.length / 2); // Find the middle index
    const firstPart = str.slice(0, middleIndex); // First half
    const secondPart = str.slice(middleIndex); // Second half
    return firstPart + " " + secondPart;
  } else {
    return str;
  }
};
export const formatNumberInternational = (number, locale = "en-US") => {
  if (isNaN(number)) {
    throw new Error("Invalid number provided.");
  }

  // Use Intl.NumberFormat to format the number
  return new Intl.NumberFormat(locale).format(number);
};
