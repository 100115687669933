import React, { ReactNode } from 'react';
import NoCountryImage from '../../../assets/images/NoContent.png';
import NoContentImage from '../../../assets/images/NotFound.png';
import NoDataSearch from '../../../assets/images/Search.png';
import Pricing from '../../../assets/images/NoContent/Pricing.svg';
import Notes from '../../../assets/images/NoContent/Notes.svg';
import Counterparty from '../../../assets/images/NoContent/Counterparty.svg';
import Add from '../../icons/Add';
import CustomButton from '../../atoms/CustomButton';
import './no_content.scss';

const NoContentCard = ({
  title,
  subtitle = null,
  tag,
  handleClick,
  onAction,
  classes,
  small = false,
  icon,
  disabled,
  children,
  color,
  type,
  height,
  fontSize,
  containerClass,
}) => {
  const getImage = (type) => {
    switch (type) {
      case 'Company':
        return NoContentImage;
      case 'Pricing':
        return Pricing;
      case 'Counterparty':
        return Counterparty;
      case 'Notes':
        return Notes;
      case 'None':
        return NoContentImage;
      default:
        return NoContentImage;
    }
  };

  return (
    <div>
      <div className={`no-content-card ${containerClass}`}>
        {small ? (
          <>
            <div
              className="no-content-circle mb-3"
              style={{ backgroundColor: color ? '#ffffff' : '' }}
            >
              <span style={{ marginTop: '0.8rem' }}>
                <i
                  className={`d-flex align-items-center `}
                  style={{ marginBottom: '10px' }}
                >
                  {icon}
                </i>
              </span>
            </div>
            <p className="no-content-title" >{title}</p>
            {subtitle && <p className="no-content-subtitle">{subtitle}</p>}
            {handleClick && (
              <CustomButton
                text={tag}
                handleClick={handleClick}
                icon={<Add />}
                classes="mt-2 d-flex"
                type="btn-primary"
                disabled={disabled}
              />
            )}
            {children}
          </>
        ) : (
          <>
            <img
              src={getImage(type)}
              alt="No Content"
              className="mb-3"
              style={{height : height ? height :"11rem"}}
            />
            <p className="no-content-title" style={{fontSize : fontSize ? fontSize : null}}>{title}</p>
            {subtitle && <p className="no-content-subtitle mt-0">{subtitle}</p>}
            {handleClick && (
              <CustomButton
                text={tag}
                handleClick={handleClick}
                icon={<Add />}
                classes={`px-2.67rem  ${classes}`}
                type="btn-primary"
                disabled={disabled}
              />
            )}

            {children}
          </>
        )}
      </div>
    </div>
  );
};

export default NoContentCard;
