import React from 'react';

const Payments = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3_54)">
        <path
          d="M15.75 17.925L13.0027 16.0417L11.0048 17.4105L9.00525 16.041L7.00575 17.409L5.0025 16.0395L2.25 17.925V2.25C2.25 1.65326 2.48705 1.08097 2.90901 0.65901C3.33097 0.237053 3.90326 0 4.5 0L13.5 0C14.0967 0 14.669 0.237053 15.091 0.65901C15.5129 1.08097 15.75 1.65326 15.75 2.25V17.925ZM5.0025 14.2215L7.005 15.591L9.00525 14.2215L11.0048 15.591L13.0035 14.2208L14.25 15.075V2.25C14.25 2.05109 14.171 1.86032 14.0303 1.71967C13.8897 1.57902 13.6989 1.5 13.5 1.5H4.5C4.30109 1.5 4.11032 1.57902 3.96967 1.71967C3.82902 1.86032 3.75 2.05109 3.75 2.25V15.075L5.0025 14.2215Z"
          fill="white"
        />
        <path
          d="M12.7502 6.00049H5.25024V7.50049H12.7502V6.00049Z"
          fill="white"
        />
        <path d="M11.2502 9H5.25024V10.5H11.2502V9Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_3_54">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Payments;
