import { Formik } from "formik";
import { Heading } from "../../../../../atoms/Heading";
import MyDropdown from "../../../../../atoms/MyDropdown";
import MyInput from "../../../../../atoms/MyInput";
import {
  collateralData,
  CurrencyData,
  documentInternalEnitity,
  drawTrachemaxTerm,
  earlyTerminationFee,
  EquityWrrants,
  FunderLookOption,
  geographicConcentrationData,
  insuranceTermSheetData,
  LawData,
  maximumInvoiceTennor,
  maxInventoryConcs,
  maxObligorConst,
  maxTermsData,
  sharePledgeData,
  SignerStaticData,
  taxAccessData,
  TaxLienData,
  TaxNoticeData,
} from "../../../../../../utils/staticData";
import MyTextArea from "../../../../../atoms/MyTextArea";
import style from "./index.module.scss";
import * as Yup from "yup";
import { emailRegex } from "../../../../../../helpers/expirationTime";
import { getData, postData } from "../../../../../../services";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import { useNavigate } from "react-router-dom";
import { width } from "@mui/system";

const SupplierCreditTerm = ({ selectedTermSheet, selectedDocuments }) => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const [signerData, setSignerData] = useState([]);

  const initialValues = {
    internal_signer: "",
    internal_signer_name: "",
    internal_entity: "",
    external_entity: "",
    ex_signer_first_name: "",
    ex_signer_last_name: "",
    ex_signer_email: "",
    max_term: "",
    currency: "",
    credit_limit: "",
    days_limit_rate: "",
    days_default_rate: "",
    annual_rate: "",
    max_advance_rate: "",
    upfront_fee: "",
    renewal_fee: "",
    early_termination: "",
    tranche_max_terms: "",
    max_obligor_concs: "",
    max_geographic_concs: "",
    max_inventory_concs: "",
    invt_adv_rate_term1: "",
    invt_adv_rate_term2: "",
    invt_adv_rate_term3: "",
    invt_adv_rate_term4: "",
    invt_adv_rate_term5: "",
    maximum_invoice_tennor: "",
    maximum_recource_day: "",
    minimum_invoice_size: "",
    minimum_invoice_volumne: "",
    broker_name: "",
    personal_guarnators: "",
    corporate_guarnators: "",
    law: "",
    collateral: "",
    insurance: "",
    share_pledge: "",
    equity_wrrants: "",
    main_book_equity: "",
    tax_notices: "",
    tax_access: "",
    tax_lien: "",
    expense_deposit: "",
    expense_cap: "",
    funder_looks_option: "",
    notes: "",
  };
  const validationSchema = Yup.object().shape({
    internal_signer: Yup.string().required("Internal signer is required"),
    internal_entity: Yup.string().required("Internal Entity is required"),
    external_entity: Yup.string().required("Extrernal entity is required"),
    ex_signer_first_name: Yup.string().required("First name is required"),
    ex_signer_last_name: Yup.string().required("Last name is required"),
    ex_signer_email: Yup.string()
      .matches(emailRegex, "Invalid email")
      .required("Email is Required"),
    // max_term: Yup.string().required("Max term is required"),
    currency: Yup.string().required("Currency is required"),
    credit_limit: Yup.string().required("Credit limit is required"),
    // days_limit_rate: Yup.string().required("Days Limit rate is required"),
    // days_default_rate: Yup.string().required("Days Default rate is required"),
    upfront_fee: Yup.string().required("Upfront fee  is required"),
    renewal_fee: Yup.string().required("Renewal  is required"),
    broker_name: Yup.string().required("broker name  is required"),
    personal_guarnators: Yup.string().required(
      "Personal guarantor  is required",
    ),
    corporate_guarnators: Yup.string().required(
      "Corporate guarantor  is required",
    ),
    law: Yup.string().required("Law  is required"),
    collateral: Yup.string().required("Collateral  is required"),
    main_book_equity: Yup.string().required("Min book equity  is required"),
    tax_notices: Yup.string().required("Tax notice  is required"),
    tax_access: Yup.string().required("Tax access  is required"),
    tax_lien: Yup.string().required("Tax lien  is required"),
    expense_deposit: Yup.string().required("Expense deposit  is required"),
    expense_cap: Yup.string().required("Expense Cap  is required"),
    notes: Yup.string().required("Notes  is required"),
  });

  const getSignerList = async () => {
    try {
      const res = await getData({
        endpoint: "signer/getSignersList",
        token: cookies.t,
      });
      setSignerData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getSignerList();
  }, []);

  const handleSubmit = async (values) => {
    console.log("values", values);

    let payload = {};
    if (selectedDocuments === "Term sheet") {
      switch (selectedTermSheet) {
        case "Supplier Credit Term Sheet":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Upload",
            DocumentsDetails: {
              internal_signer_name: values.internal_signer_name,
              internal_signer: values.internal_signer,
              internal_entity: values.internal_entity,
              external_entity: values.external_entity,
              external_entity_first_name: values.ex_signer_first_name,
              external_entity_last_name: values.ex_signer_last_name,
              external_entity_email: values.ex_signer_email,
              max_term: values.max_term,
              currency: values.currency,
              credit_limit: values.credit_limit,
              days_limit_rate: values.days_limit_rate,
              days_default_rate: values.days_default_rate,
              upfront_fee: values.upfront_fee,
              renewal_fee: values.renewal_fee,
              broker_name: values.broker_name,
              personal_guarn: values.personal_guarnators,
              corporate_guarn: values.corporate_guarnators,
              law: values.law,
              collateral: values.collateral,
              main_book_equity: values.main_book_equity,
              tax_notices: values.tax_notices,
              tax_access: values.tax_access,
              tax_lien: values.tax_lien,
              expense_deposit: values.expense_cap,
              expense_cap: values.expense_cap,
              notes: values.notes,
            },
          };
          break;
        case "Accounts Receivable Line Term Sheet":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Uploaded",
            DocumentsDetails: {
              internal_signer_name: values.internal_signer_name,
              internal_signer: values.internal_signer,
              internal_entity: values.internal_entity,
              external_entity: values.external_entity,
              external_entity_first_name: values.ex_signer_first_name,
              external_entity_last_name: values.ex_signer_last_name,
              external_entity_email: values.ex_signer_email,
              currency: values.currency,
              credit_limit: values.credit_limit,
              annual_rate: values.annual_rate,
              max_adv_rate: values.max_advance_rate,
              upfront_fee: values.upfront_fee,
              renewal_fee: values.renewal_fee,
              tranche_max_terms: values.tranche_max_terms,
              max_obligor_concs: values.max_obligor_concs,
              max_geographic_concs: values.max_geographic_concs,
              broker_name: values.broker_name,
              personal_guarn: values.personal_guarnators,
              corporate_guarn: values.corporate_guarnators,
              law: values.law,
              collateral: values.collateral,
              insurance: values.insurance,
              share_pledge: values.share_pledge,
              equity_warrants: values.equity_wrrants,
              main_book_equity: values.main_book_equity,
              tax_notices: values.tax_notices,
              tax_access: values.tax_access,
              tax_lien: values.tax_lien,
              expense_deposit: values.expense_deposit,
              expense_cap: values.expense_cap,
              funder_look_option: values.funder_looks_option,
              notes: values.notes,
              early_termination_fee: values.early_termination_fee,
            },
          };
          break;
        case "Account Receivable + Inventory Term Sheet":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Uploaded",
            DocumentsDetails: {
              internal_signer_name: values.internal_signer_name,
              internal_signer: values.internal_signer,
              internal_entity: values.internal_entity,
              external_entity: values.external_entity,
              external_entity_first_name: values.ex_signer_first_name,
              external_entity_last_name: values.ex_signer_last_name,
              external_entity_email: values.ex_signer_email,
              currency: values.currency,
              credit_limit: values.credit_limit,
              annual_rate: values.annual_rate,
              max_adv_rate: values.max_advance_rate,
              upfront_fee: values.upfront_fee,
              renewal_fee: values.renewal_fee,
              tranche_max_terms: values.tranche_max_terms,
              max_obligor_concs: values.max_obligor_concs,
              max_geographic_concs: values.max_geographic_concs,
              max_inventory_concs: "",
              inventory_adv_rate1: values.invt_adv_rate_term1,
              inventory_adv_rate2: values.invt_adv_rate_term2,
              inventory_adv_rate3: values.invt_adv_rate_term3,
              inventory_adv_rate4: values.invt_adv_rate_term4,
              inventory_adv_rate5: values.invt_adv_rate_term5,
              broker_name: values.broker_name,
              personal_guarn: values.personal_guarnators,
              corporate_guarn: values.corporate_guarnators,
              law: values.law,
              collateral: values.collateral,
              insurance: values.insurance,
              share_pledge: values.share_pledge,
              equity_warrants: values.equity_wrrants,
              main_book_equity: values.main_book_equity,
              tax_notices: values.tax_notices,
              tax_access: values.tax_access,
              tax_lien: values.tax_lien,
              expense_deposit: values.expense_deposit,
              expense_cap: values.expense_cap,
              funder_look_option: values.funder_looks_option,
              notes: values.notes,
              early_termination_fee: values.early_termination_fee,
            },
          };
          break;
        case "Factoring Term Sheet":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Uploaded",
            DocumentsDetails: {
              internal_signer_name: values.internal_signer_name,
              internal_signer: values.internal_signer,
              internal_entity: values.internal_entity,
              external_entity: values.external_entity,
              external_entity_first_name: values.ex_signer_first_name,
              external_entity_last_name: values.ex_signer_last_name,
              external_entity_email: values.ex_signer_email,
              currency: values.currency,
              credit_limit: values.credit_limit,
              annual_rate: values.annual_rate,
              max_adv_rate: values.max_advance_rate,
              upfront_fee: values.upfront_fee,
              renewal_fee: values.renewal_fee,
              tranche_max_terms: values.tranche_max_terms,
              minimum_invoice_size: values.minimum_invoice_size,
              minimum_invoice_volumne: values.minimum_invoice_volumne,
              broker_name: values.broker_name,
              personal_guarn: values.personal_guarnators,
              corporate_guarn: values.corporate_guarnators,
              law: values.law,
              collateral: values.collateral,
              insurance: values.insurance,
              share_pledge: values.share_pledge,
              equity_warrants: values.equity_wrrants,
              main_book_equity: values.main_book_equity,
              tax_notices: values.tax_notices,
              tax_access: values.tax_access,
              tax_lien: values.tax_lien,
              expense_deposit: values.expense_deposit,
              expense_cap: values.expense_cap,
              funder_look_option: values.funder_looks_option,
              notes: values.notes,
              early_termination_fee: values.early_termination_fee,
            },
          };
          break;

        case "Confidential Invoice Discounting Term Sheet":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Uploaded",
            DocumentsDetails: {
              internal_signer_name: values.internal_signer_name,
              internal_signer: values.internal_signer,
              internal_entity: values.internal_entity,
              external_entity: values.external_entity,
              external_entity_first_name: values.ex_signer_first_name,
              external_entity_last_name: values.ex_signer_last_name,
              external_entity_email: values.ex_signer_email,
              currency: values.currency,
              credit_limit: values.credit_limit,
              annual_rate: values.annual_rate,
              max_adv_rate: values.max_advance_rate,
              upfront_fee: values.upfront_fee,
              renewal_fee: values.renewal_fee,
              tranche_max_terms: values.tranche_max_terms,
              maximum_invoice_tennor: values.maximum_invoice_tennor,
              maximum_recource_day: values.maximum_recource_day,
              minimum_invoice_size: values.minimum_invoice_size,
              minimum_invoice_volumne: values.minimum_invoice_volumne,
              broker_name: values.broker_name,
              personal_guarn: values.personal_guarnators,
              corporate_guarn: values.corporate_guarnators,
              law: values.law,
              collateral: values.collateral,
              insurance: values.insurance,
              share_pledge: values.share_pledge,
              equity_warrants: values.equity_wrrants,
              main_book_equity: values.main_book_equity,
              tax_notices: values.tax_notices,
              tax_access: values.tax_access,
              tax_lien: values.tax_lien,
              expense_deposit: values.expense_deposit,
              expense_cap: values.expense_cap,
              funder_look_option: values.funder_looks_option,
              notes: values.notes,
            },
          };
          break;

        case "Promissory Note Term Sheet":
          payload = {
            documentType: selectedDocuments,
            documentSubType: selectedTermSheet,
            status: "Uploaded",
            DocumentsDetails: {
              internal_signer_name: values.internal_signer_name,
              internal_signer: values.internal_signer,
              internal_entity: values.internal_entity,
              external_entity: values.external_entity,
              external_entity_first_name: values.ex_signer_first_name,
              external_entity_last_name: values.ex_signer_last_name,
              external_entity_email: values.ex_signer_email,
              max_term: values.max_term,
              currency: values.currency,
              credit_limit: values.credit_limit,
              days_limit_rate: values.days_limit_rate,
              days_default_rate: values.days_default_rate,
              upfront_fee: values.upfront_fee,
              renewal_fee: values.renewal_fee,
              broker_name: values.broker_name,
              personal_guarn: values.personal_guarnators,
              corporate_guarn: values.corporate_guarnators,
              law: values.law,
              collateral: values.collateral,
              main_book_equity: values.main_book_equity,
              tax_notices: values.tax_notices,
              tax_access: values.tax_access,
              tax_lien: values.tax_lien,
              expense_deposit: values.expense_cap,
              expense_cap: values.expense_cap,
              notes: values.notes,
            },
          };
          break;
        default:
          break;
      }
    }
    console.log("payload", payload);
    try {
      const res = await postData({
        endpoint: "documents/create/documents",
        token: cookies.t,
        data: payload,
      });
      if (res) {
        toast.success("Documents Created Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        navigate("/dashboard/tools");
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          values,
          touched,
          errors,
          dirty,
          setFieldValue,
          setValues,
          setFieldTouched,
          handleBlur,
          handleSubmit,
          isValid,
          isSubmitting,
        }) => {
          return (
            <>
              <div className="mt-4">
                <Heading className="HeadingStyle">Signer Selection</Heading>
                <div
                  className="d-flex flex-wrap"
                  style={{ columnGap: "1.36%", rowGap: "16px" }}
                >
                  <MyDropdown
                    placeholder="Internal Singer"
                    data={SignerStaticData}
                    onChange={(option) => {
                      setFieldValue("internal_signer", option?.value);
                      setFieldValue("internal_signer_name", option.label);
                    }}
                    touched={touched.internal_signer}
                    selectedDate={values.internal_signer}
                    errors={errors.internal_signer}
                    handleBlur={(e) => {
                      setFieldTouched("internal_signer", true);
                      handleBlur(e);
                    }}
                    format="all"
                    width="32.426%"
                    required
                  />
                  <MyDropdown
                    placeholder="Internal Entity"
                    data={documentInternalEnitity}
                    onChange={(option) => {
                      setFieldValue("internal_entity", option);
                    }}
                    touched={touched.internal_entity}
                    selectedDate={values.internal_entity}
                    errors={errors.internal_entity}
                    handleBlur={(e) => {
                      setFieldTouched("internal_entity", true);
                      handleBlur(e);
                    }}
                    width="32.426%"
                    required
                  />
                  <MyInput
                    type="text"
                    name="external_entity"
                    customholder="External Entity"
                    width="32.426%"
                    required
                  />
                  <MyInput
                    type="text"
                    name="ex_signer_first_name"
                    customholder="External Signer First Name"
                    width="32.426%"
                    required
                  />
                  <MyInput
                    type="text"
                    name="ex_signer_last_name"
                    customholder="External Signer Last Name"
                    width="32.426%"
                    required
                  />
                  <MyInput
                    type="text"
                    name="ex_signer_email"
                    customholder="External Signer Email"
                    width="32.426%"
                    required
                  />
                </div>
              </div>
              <div className="mt-4">
                <Heading className="HeadingStyle">Document Variables</Heading>
                <div
                  className="d-flex flex-wrap"
                  style={{ columnGap: "1.36%", rowGap: "16px" }}
                >
                  {(selectedTermSheet === "Supplier Credit Term Sheet" ||
                    selectedTermSheet === "Promissory Note Term Sheet") && (
                    <MyDropdown
                      placeholder="Max Term"
                      data={maxTermsData}
                      onChange={(option) => {
                        setFieldValue("max_term", option);
                      }}
                      touched={touched.max_term}
                      selectedDate={values.max_term}
                      errors={errors.max_term}
                      handleBlur={(e) => {
                        setFieldTouched("max_term", true);
                        handleBlur(e);
                      }}
                      width="32.426%"
                    />
                  )}
                  <MyDropdown
                    placeholder="Currency"
                    data={CurrencyData}
                    onChange={(option) => {
                      setFieldValue("currency", option);
                    }}
                    touched={touched.currency}
                    selectedDate={values.currency}
                    errors={errors.currency}
                    handleBlur={(e) => {
                      setFieldTouched("currency", true);
                      handleBlur(e);
                    }}
                    width="32.426%"
                    required
                  />
                  <MyInput
                    type="text"
                    name="credit_limit"
                    customholder="Credit Limit"
                    width="32.426%"
                    required
                  />
                  {(selectedTermSheet === "Supplier Credit Term Sheet" ||
                    selectedTermSheet === "Promissory Note Term Sheet") && (
                    <MyInput
                      type="text"
                      name="days_limit_rate"
                      customholder="30 Day Limit"
                      width="32.426%"
                      info="1.25%"
                      
                    />
                  )}
                  {(selectedTermSheet === "Supplier Credit Term Sheet" ||
                    selectedTermSheet === "Promissory Note Term Sheet") && (
                    <MyInput
                      type="text"
                      name="days_default_rate"
                      customholder="30 Day Default Rate"
                      width="32.426%"
                      info="2%"
                    />
                  )}
                  {(selectedTermSheet ===
                    "Accounts Receivable Line Term Sheet" ||
                    selectedTermSheet ===
                      "Account Receivable + Inventory Term Sheet" ||
                    selectedTermSheet === "Factoring Term Sheet" ||
                    selectedTermSheet ===
                      "Confidential Invoice Discounting Term Sheet") && (
                    <MyInput
                      type="text"
                      name="annual_rate"
                      customholder="Annual Rate(Includes Base, spread and floor)"
                      width="32.426%"
                      info="One Month Term SOFR + 11% APR,with 2% SOFR Floor"
                    />
                  )}
                  {(selectedTermSheet ===
                    "Accounts Receivable Line Term Sheet" ||
                    selectedTermSheet ===
                      "Account Receivable + Inventory Term Sheet" ||
                    selectedTermSheet === "Factoring Term Sheet" ||
                    selectedTermSheet ===
                      "Confidential Invoice Discounting Term Sheet") && (
                    <MyInput
                      type="text"
                      name="max_advance_rate"
                      customholder="Max Advance Rate"
                      width="32.426%"
                      info="85%"
                    />
                  )}
                  <MyInput
                    type="text"
                    name="upfront_fee"
                    customholder="Upfront Fee"
                    width="32.426%"
                    info="2% / Waived"
                    required
                  />
                  <MyInput
                    type="text"
                    name="renewal_fee"
                    customholder="Renewal Fee"
                    width="32.426%"
                    info="2% / Waived"
                    required
                  />
                  {(selectedTermSheet ===
                    "Accounts Receivable Line Term Sheet" ||
                    selectedTermSheet ===
                      "Account Receivable + Inventory Term Sheet" ||
                    selectedTermSheet === "Factoring Term Sheet" ||
                    selectedTermSheet ===
                      "Confidential Invoice Discounting Term Sheet") && (
                    <MyDropdown
                      placeholder="Early Termination Fee"
                      data={earlyTerminationFee}
                      onChange={(option) => {
                        setFieldValue("early_termination_fee", option);
                      }}
                      width="32.426%"
                    />
                  )}
                  {(selectedTermSheet ===
                    "Accounts Receivable Line Term Sheet" ||
                    selectedTermSheet ===
                      "Account Receivable + Inventory Term Sheet" ||
                    selectedTermSheet === "Factoring Term Sheet") && (
                    <MyDropdown
                      placeholder="Draw/Tranche Max Terms"
                      data={drawTrachemaxTerm}
                      onChange={(option) => {
                        setFieldValue("tranche_max_terms", option);
                      }}
                      width="32.426%"
                    />
                  )}
                  {(selectedTermSheet ===
                    "Accounts Receivable Line Term Sheet" ||
                    selectedTermSheet ===
                      "Account Receivable + Inventory Term Sheet") && (
                    <MyDropdown
                      placeholder="Max Obligor Concentration"
                      data={maxObligorConst}
                      onChange={(option) => {
                        setFieldValue("max_obligor_concs", option);
                      }}
                      width="32.426%"
                    />
                  )}
                  {(selectedTermSheet ===
                    "Accounts Receivable Line Term Sheet" ||
                    selectedTermSheet ===
                      "Account Receivable + Inventory Term Sheet") && (
                    <MyDropdown
                      placeholder="Max Geographic Concentration"
                      data={geographicConcentrationData}
                      onChange={(option) => {
                        setFieldValue("max_geographic_concs", option);
                      }}
                      width="32.426%"
                    />
                  )}
                  {selectedTermSheet ===
                    "Account Receivable + Inventory Term Sheet" && (
                    <MyDropdown
                      placeholder="Max Invetory Concentration"
                      data={maxInventoryConcs}
                      onChange={(option) => {
                        setFieldValue("max_inventory_concs", option);
                      }}
                      width="32.426%"
                    />
                  )}
                  {selectedTermSheet ===
                    "Account Receivable + Inventory Term Sheet" && (
                    <MyInput
                      type="text"
                      name="invt_adv_rate_term1"
                      customholder="Inventory Advance Rate Terms 1"
                      width="32.426%"
                      info="75% of Current Inventory aged 1 year of less"
                    />
                  )}
                  {selectedTermSheet ===
                    "Account Receivable + Inventory Term Sheet" && (
                    <MyInput
                      type="text"
                      name="invt_adv_rate_term2"
                      customholder="Inventory Advance Rate Terms 2"
                      width="32.426%"
                      info="30% of inventory aged more than 365 days less than 545 days"
                    />
                  )}
                  {selectedTermSheet ===
                    "Account Receivable + Inventory Term Sheet" && (
                    <MyInput
                      type="text"
                      name="invt_adv_rate_term3"
                      customholder="Inventory Advance Rate Terms 3"
                      width="32.426%"
                      info="Intentionally Left Blank"
                    />
                  )}
                  {selectedTermSheet ===
                    "Account Receivable + Inventory Term Sheet" && (
                    <MyInput
                      type="text"
                      name="invt_adv_rate_term4"
                      customholder="Inventory Advance Rate Terms 4"
                      width="32.426%"
                      info="Intentionally Left Blank"
                    />
                  )}
                  {selectedTermSheet ===
                    "Account Receivable + Inventory Term Sheet" && (
                    <MyInput
                      type="text"
                      name="invt_adv_rate_term5"
                      customholder="Inventory Advance Rate Terms 5"
                      width="32.426%"
                      info="Intentionally Left Blank"
                    />
                  )}
                  {selectedTermSheet ===
                    "Confidential Invoice Discounting Term Sheet" && (
                    <MyDropdown
                      placeholder="Maximum Invoice Tennor"
                      data={maximumInvoiceTennor}
                      onChange={(option) => {
                        setFieldValue("maximum_invoice_tennor", option);
                      }}
                      width="32.426%"
                    />
                  )}
                  {selectedTermSheet ===
                    "Confidential Invoice Discounting Term Sheet" && (
                    <MyDropdown
                      placeholder="Maximum Recourse Day"
                      data={maximumInvoiceTennor}
                      onChange={(option) => {
                        setFieldValue("maximum_recource_day", option);
                      }}
                      width="32.426%"
                    />
                  )}
                  {(selectedTermSheet === "Factoring Term Sheet" ||
                    selectedTermSheet ===
                      "Confidential Invoice Discounting Term Sheet") && (
                    <MyInput
                      type="text"
                      name="minimum_invoice_size"
                      customholder="Minimum Invoice Size"
                      width="32.426%"
                    />
                  )}
                  {(selectedTermSheet === "Factoring Term Sheet" ||
                    selectedTermSheet ===
                      "Confidential Invoice Discounting Term Sheet") && (
                    <MyInput
                      type="text"
                      name="minimum_invoice_volumne"
                      customholder="Minimum Invoice Volume"
                      width="32.426%"
                    />
                  )}
                  <MyInput
                    type="text"
                    name="broker_name"
                    customholder="Broker / Platform name"
                    width="32.426%"
                    info="ABC Company, LLC"
                    required
                  />
                  <MyInput
                    type="text"
                    name="personal_guarnators"
                    customholder="Personal Guarantors"
                    width="32.426%"
                    info="John Smith,John Doe"
                    required
                  />
                  <MyInput
                    type="text"
                    name="corporate_guarnators"
                    customholder="Corporate Guarantors"
                    width="32.426%"
                    info="ABC Company, LLC, 123 Company LLC"
                    required
                  />
                  <MyDropdown
                    placeholder="Law"
                    data={LawData}
                    onChange={(option) => {
                      setFieldValue("law", option);
                    }}
                    touched={touched.law}
                    selectedDate={values.law}
                    errors={errors.law}
                    handleBlur={(e) => {
                      setFieldTouched("law", true);
                      handleBlur(e);
                    }}
                    width="32.426%"
                    required
                  />
                  <MyDropdown
                    placeholder="Collateral"
                    data={collateralData}
                    onChange={(option) => {
                      setFieldValue("collateral", option);
                    }}
                    touched={touched.collateral}
                    selectedDate={values.collateral}
                    errors={errors.collateral}
                    handleBlur={(e) => {
                      setFieldTouched("collateral", true);
                      handleBlur(e);
                    }}
                    width="32.426%"
                    required
                  />
                  {(selectedTermSheet ===
                    "Accounts Receivable Line Term Sheet" ||
                    selectedTermSheet ===
                      "Account Receivable + Inventory Term Sheet" ||
                    selectedTermSheet === "Factoring Term Sheet" ||
                    selectedTermSheet ===
                      "Confidential Invoice Discounting Term Sheet") && (
                    <MyDropdown
                      placeholder="Insurance"
                      data={insuranceTermSheetData}
                      onChange={(option) => {
                        setFieldValue("insurance", option);
                      }}
                      width="32.426%"
                    />
                  )}
                  {(selectedTermSheet ===
                    "Accounts Receivable Line Term Sheet" ||
                    selectedTermSheet ===
                      "Account Receivable + Inventory Term Sheet" ||
                    selectedTermSheet === "Factoring Term Sheet" ||
                    selectedTermSheet ===
                      "Confidential Invoice Discounting Term Sheet") && (
                    <MyDropdown
                      placeholder="Share Pledge"
                      data={sharePledgeData}
                      onChange={(option) => {
                        setFieldValue("share_pledge", option);
                      }}
                      width="32.426%"
                    />
                  )}
                  {(selectedTermSheet ===
                    "Accounts Receivable Line Term Sheet" ||
                    selectedTermSheet ===
                      "Account Receivable + Inventory Term Sheet" ||
                    selectedTermSheet === "Factoring Term Sheet" ||
                    selectedTermSheet ===
                      "Confidential Invoice Discounting Term Sheet") && (
                    <MyDropdown
                      placeholder="Equity Warrants"
                      data={EquityWrrants}
                      onChange={(option) => {
                        setFieldValue("equity_wrrants", option);
                      }}
                      width="32.426%"
                    />
                  )}
                  <MyInput
                    type="text"
                    name="main_book_equity"
                    customholder="Min Book Equity"
                    width="32.426%"
                    required
                  />
                  <MyDropdown
                    placeholder="Tax Notices"
                    data={TaxNoticeData}
                    onChange={(option) => {
                      setFieldValue("tax_notices", option);
                    }}
                    touched={touched.tax_notices}
                    selectedDate={values.tax_notices}
                    errors={errors.tax_notices}
                    handleBlur={(e) => {
                      setFieldTouched("tax_notices", true);
                      handleBlur(e);
                    }}
                    width="32.426%"
                    required
                  />
                  <MyDropdown
                    placeholder="Tax Access"
                    data={taxAccessData}
                    onChange={(option) => {
                      setFieldValue("tax_access", option);
                    }}
                    touched={touched.tax_access}
                    selectedDate={values.tax_access}
                    errors={errors.tax_access}
                    handleBlur={(e) => {
                      setFieldTouched("tax_access", true);
                      handleBlur(e);
                    }}
                    width="32.426%"
                    required
                  />
                  <MyDropdown
                    placeholder="Tax Lien"
                    data={TaxLienData}
                    onChange={(option) => {
                      setFieldValue("tax_lien", option);
                    }}
                    touched={touched.tax_lien}
                    selectedDate={values.tax_lien}
                    errors={errors.tax_lien}
                    handleBlur={(e) => {
                      setFieldTouched("tax_lien", true);
                      handleBlur(e);
                    }}
                    width="32.426%"
                    required
                  />
                  <MyInput
                    type="text"
                    name="expense_deposit"
                    customholder="Expense Deposit"
                    width="32.426%"
                    required
                  />
                  <MyInput
                    type="text"
                    name="expense_cap"
                    customholder="Expense Cap"
                    width="32.426%"
                    required
                  />
                  {(selectedTermSheet ===
                    "Accounts Receivable Line Term Sheet" ||
                    selectedTermSheet ===
                      "Account Receivable + Inventory Term Sheet" ||
                    selectedTermSheet === "Factoring Term Sheet" ||
                    selectedTermSheet ===
                      "Confidential Invoice Discounting Term Sheet") && (
                    <MyDropdown
                      placeholder="Funder Look options"
                      data={FunderLookOption}
                      onChange={(option) => {
                        setFieldValue("funder_looks_option", option);
                      }}
                      width="32.426%"
                    />
                  )}
                  <MyTextArea
                    as="textarea"
                    customholder="Other Notes and Adjustments:"
                    name="notes"
                    style={{ width: "32.426%" }}
                    info={
                      selectedTermSheet ===
                      "Account Receivable + Inventory Term Sheet"
                        ? "TERM ABC is adjusted to...."
                        : "Intentionally Left Blank or Term ABC adjusted to...."
                    }
                    required
                  />
                </div>
              </div>
              {/* <div className="mt-4">
                <Heading className="HeadingStyle">Document Variables</Heading>
                <div
                  className="d-flex flex-wrap"
                  style={{ columnGap: "1.36%", rowGap: "16px" }}
                > */}
              {/* </div>
              </div> */}
              {/* <div className="mt-4">
                <Heading className="HeadingStyle">Document Variables</Heading>
                <div
                  className="d-flex flex-wrap"
                  style={{ columnGap: "1.36%", rowGap: "16px" }}
                > */}
              {/* </div>
              </div> */}
              <div className={style.buttonWrap}>
                <button
                  className="btn btn-primary w-30 mt-2 btn-xl"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => {
                    handleSubmit(values);
                  }}
                >
                  Generate Document
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-light"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};
export default SupplierCreditTerm;
