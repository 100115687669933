import { Heading } from "../../../atoms/Heading";
import NestedCustomMenu from "../../../molecules/CustomMenu/NestedCustomMenu";
import { useEffect, useState } from "react";
import InfoCard from "../../../molecules/DashboardMolecules/InfoCard";
import "./styles.scss";
import Payments from "../../../icons/Payments";
import Notes from "../../../icons/Notes";
import Reports from "../../../icons/reports";
import Funding from "../../../icons/Funding";
import Settings from "../../../icons/setting";
import Counterparty from "../../../icons/Counterparty";
import { IconButton } from "@mui/material";
import IconCircle from "../../../atoms/IconCircle";
import { useNavigate } from "react-router-dom";
import FundingSummary from "./Tables/FundingSummary";
import ClientSummary from "./Tables/ClientSummary";
import ArrowDown from "../../../icons/ArrowDown";
import Charts from "./Charts";

import { getData } from "../../../../services/index";
import { useCookies } from "react-cookie";
import { getCurrencyRate } from "../../../../services/currencyConvertor";
import { convertAndAddCurrencies } from "./helper/currency";
import Interest from "../../../icons/Interest";
import CustomDateRangePicker from "../../../atoms/DateRangePicker";

const Dashboard = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t"]);
  const [widgetData, setWidgetData] = useState();
  const [currencyConversion, setCurrencyConversion] = useState(null);
  const [date, setDate] = useState([null, null]);

  console.log('date --> ',date)

  const getWidgetData = async () => {
    const res = await getData({
      endpoint: "dashboard/getWidgets",
      token: cookies.t,
      params : {start_date : date?.start_date?.replace('.000',''), end_date : date?.end_date?.replace('.000','')}
    });

    if (res?.data) {
      console.log("res", res?.datah);
      setWidgetData(res?.data);
    }
  };

  useEffect(() => {
    const fetchCurrencyRate = async () => {
      try {
        const res = await getCurrencyRate({ date: new Date() });
        setCurrencyConversion(res);
      } catch (error) {
        console.error("Error fetching currency rate:", error);
      }
    };

    fetchCurrencyRate(); // Call the async function
  }, []);

  useEffect(() => {
// Call the synchronous function (or async if needed)
 getWidgetData();
  },[date])
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <Heading className="HeadingStyle"> Dashboard</Heading>

        <CustomDateRangePicker setDate={setDate}/>
      </div>
      <div className="dashboard-container">
        <div className="widgets">
          <InfoCard
            data={widgetData?.total_deals}
            title="Deals Funded"
            icon={<Payments />}
            color={"#efefef"}
            iconSize={"24px"}
          />
          <InfoCard
            data={convertAndAddCurrencies(
              widgetData?.totalFundedData,
              currencyConversion,'million'
            )}
            title="Amount Funded"
            icon={<Funding />}
            color={"#efefef"}
            iconSize={"24px"}
          />
          <InfoCard
            data={widgetData?.total_open_clients}
            title="Current Clients"
            icon={<Counterparty />}
            color={"#efefef"}
            iconSize={"24px"}
          />
          <InfoCard
            data={convertAndAddCurrencies(
              widgetData?.totalInvestedData,
              currencyConversion,'million'
            )}
            title="Invested Amount"
            icon={<Payments />}
            color={"#efefef"}
            iconSize={"24px"}
          />
          <InfoCard
            data={convertAndAddCurrencies(
              widgetData?.totalInterestData,
              currencyConversion,'million'
            )}
            title="Interest Accrued"
            icon={<Interest />}
            color={"#efefef"}
            iconSize={"24px"}
          />
        </div>

        <div className="main">
          <div className="left">
            <div className="common">
              <Heading className={"HeadingSubStyle"}>Funding Summary</Heading>

              <div className="mt-2">
                <FundingSummary date={date}/>
              </div>
            </div>
            <div className="common">
              <Heading className={"HeadingSubStyle"}>Client Summary</Heading>
              <div className="mt-2">
                <ClientSummary date={date}/>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="common">
              <Heading className={"HeadingSubStyle"}>Quick Links</Heading>
              <div className="quick-links">
                <div
                  className="quick-link"
                  onClick={() => navigate("funding/add")}
                >
                  <IconCircle
                    icon={<Funding />}
                    color={"#efefef"}
                    iconSize={"18px"}
                    size="50px"
                  />
                  <div className="description">New Funding Request</div>
                </div>

                <div
                  className="quick-link"
                  onClick={() => navigate("reports/portfolio")}
                >
                  <IconCircle
                    icon={<Reports />}
                    color={"#efefef"}
                    iconSize={"18px"}
                    size="50px"
                  />
                  <div className="description">Portfolio</div>
                </div>
                <div className="quick-link" onClick={() => navigate("notes")}>
                  <IconCircle
                    icon={<Notes />}
                    color={"#efefef"}
                    iconSize={"18px"}
                    size="50px"
                  />
                  <div className="description">Notes</div>
                </div>

                <div className="quick-link" onClick={() => navigate("setting/rate-management")}>
                  <IconCircle
                    icon={<Settings />}
                    color={"#efefef"}
                    iconSize={"18px"}
                    size="50px"
                  />
                  <div className="description">Rates</div>
                </div>
              </div>
            </div>
            <div className="common">
              <Charts
                conversionRate={currencyConversion}
                title={"Exposure"}
                type={"exposure"}
                date={date}
              />
            </div>
            <div className="common">
              <Charts
                conversionRate={currencyConversion}
                title={"Funds Exposure"}
                type={"investment"}
                date={date}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
