
export const errorStyles = {
  minHeight: "44px",
  borderRadius: "10px",
  background: "#BE0F00",
  width: "max-content",
  top: "40px",
  boxShadow: "0px 6px 10px 0px rgba(30, 39, 59, 0.10)",
  color: "#ffffff",
  position: "relative",
  fontWeight : '600',
  fontSize : '14px'
};

export const confimationStyles = {
  height: "44px",
  borderRadius: "10px",
  background: "#1CA076",
  top: "40px",
  boxShadow: "0px 6px 10px 0px rgba(30, 39, 59, 0.10)",
  color: "#ffffff",
  position: "relative",
  fontWeight : '600',
  fontSize : '14px'
};

export const warningStyles = {
  height: "44px",
  borderRadius: "10px",
  background: "rgb(255 193 7)",
  top: "40px",
  boxShadow: "0px 6px 10px 0px rgba(30, 39, 59, 0.10)",
  color: "#ffffff",
  position: "relative",
  fontWeight : '600',
  fontSize : '14px'
};
