import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import CustomButton from "../../../../../atoms/CustomButton";
import PageHeader from "../../../../../atoms/PageHeader";
import BackBtn from "../../../../../atoms/BackBtn";
import PageCard from "../../../../../atoms/PageCard";
import PermissionSelector from "./PermissionSelector";
import InputLayout from "../../../../../atoms/InputLayout";
import Error from "../../../../../atoms/Error";
import { useNavigate, useParams } from "react-router-dom";
import { postData, getData, patchData } from "../../../../../../services";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../../assets/styles/components/toast";
import { useCookies } from "react-cookie";
import MyInput from "../../../../../atoms/MyInput";
import Breadcrumb from "../../../../../molecules/Breadcrumb";
import { Heading } from "../../../../../atoms/Heading";
import Checkbox from "../../../../../atoms/Checkbox";
import MinusIcon from "../../../../../icons/minusIcon";
import AddIcon from "../../../../../icons/Add";
import "../../../../../atoms/Checkbox/style.scss";
import { useSelector } from "react-redux";

const Add = ({ type = "add" }) => {
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [isSaving, setIsSaving] = useState(false);
  let accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.["User Management"];
  const [initialValues, setInitialValues] = useState({});
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getData({
        endpoint: "role/getRoleByID",
        token: cookies.t,
        params: { id: id },
      });
      setInitialValues(res);
    };

    if (type === "edit") {
      fetchData();
    }
  }, []);

  const modules = [
    { name: "Dashboard", identifier: "dashboard" , allowed : ['view','none','none','none']},
    { name: "Funding", identifier: "funding" ,allowed : ['view','add/edit','none','delete']},
    { name: "Payment", identifier: "payment" ,allowed : ['view','add/edit','none','delete']},
    { name: "Notes", identifier: "notes" },
    { name: "Tools", identifier: "tools" },
    { name: "Counterparties", identifier: "counterparties" },
    { name: "Reports", identifier: "reports",allowed : ['view','none','export','none']},
    // { name: "Onboarding", identifier: "onboarding" },
    { name: "Monitoring", identifier: "monitoring" ,allowed : ['view','add/edit','none','none']},
    { name: "Signers", identifier: "signers" },
    { name: "Brokers", identifier: "brokers" },
    { name: "Investment", identifier: "investment" },
    { name: "Insurance", identifier: "insurance" },
    { name: "Rates", identifier: "rates" ,allowed : ['view','add/edit','none','delete']},
    { name: "Pricing", identifier: "pricing" },
    { name: "Covenants", identifier: "covenants" ,allowed : ['view','add/edit','none','delete']},
    { name: "Reporting Requirements", identifier: "reporting-requirnments",allowed : ['view','add/edit','none','delete'] },
    { name: "User Management", identifier: "user-management",allowed : ['view','add/edit','none','delete'] },
  ];

  const initialData = {
    role_name: initialValues?.data?.role_name || "",
    data: initialValues?.data?.role_access || [],
  };

  const validationSchema = Yup.object().shape({
    role_name: Yup.string().required("Role is required"),
  });

  const handleSubmit = async (values) => {
    setIsSaving(true);

    try {
      const res = await postData({
        endpoint: "/role/create",
        token: cookies.t,
        data: { role_name: values.role_name, role_access: values.data },
      });
      if (res) {
        toast.success("Role Created Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate("/dashboard/setting/user-management", {
          state: { customState: "Role Management" },
        });
      }
    } catch (error) {
      toast.error("An Error Occurred. Please try again later.", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleEdit = async (values) => {
    setIsSaving(true);

    try {
      const res = await patchData({
        endpoint: "role/update",
        token: cookies.t,
        params: { id: id },
        data: { role_name: values.role_name, role_access: values.data },
      });
      if (res) {
        toast.success("Role Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate("/dashboard/setting/user-management", {
          state: { customState: "Role Management" },
        });
      }
    } catch (error) {
      toast.error("An Error Occurred. Please try again later.", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      validateOnChange
    >
      {({
        dirty,
        isValid,
        values,
        setValues,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleBlur,
        resetForm,
      }) => (
        <>
          <Breadcrumb
            labels={[
              "Role Management",
              type === "add" ? "Create Role" : "Update Role",
            ]}
            customState="Role Management"
            stepsToRemove={type !== "add" ? 1 : 0}
          />
          <Heading className="addHeaderStyle">
            {type === "add" ? "Add Role" : "Edit Role"}
          </Heading>
          <div className="row mb-4 mt-3">
            <MyInput
              type="text"
              id="role_name"
              name="role_name"
              customholder="Enter Role"
              width="32%"
              required
            />
          </div>
          <div className="w-100 d-flex justify-content-between mb-3 align-items-center">
            <Heading className="h5 mb-3">Assign Permissions</Heading>
            {roleData && roleData?.["add/edit"] && (
              <>
                {type === "add" ? (
                  <CustomButton
                    iconRequired={false}
                    type="btn-primary "
                    buttonType="submit"
                    handleClick={() => handleSubmit(values)}
                    disabled={!isValid || !dirty || isSaving}
                  >
                    Create Role
                    {isSaving && (
                      <span
                        className="spinner-border spinner-border-sm ms-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </CustomButton>
                ) : (
                  <CustomButton
                    iconRequired={false}
                    size="btn-lg"
                    type="btn-primary"
                    buttonType="submit"
                    handleClick={() => handleEdit(values)}
                    disabled={!isValid || isSaving}
                  >
                    Update Role
                    {isSaving && (
                      <span
                        className="spinner-border spinner-border-sm ms-2 text-light"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </CustomButton>
                )}
              </>
            )}
          </div>
          <ParentComponent
            modules={modules}
            initialData={initialData}
            values={values}
            setFieldValue={setFieldValue}
            selectedPermissions={selectedPermissions}
            setSelectedPermissions={setSelectedPermissions}
          />

          <div className="d-flex w-100 justify-content-end align-items-center mt-3">
            {/* <CustomButton
              text="Cancel"
              size="btn-lg"
              iconRequired={false}
              handleClick={() => {
                navigate(-1);
              }}
            /> */}
            {roleData && roleData?.["add/edit"] && (
              <div className="d-flex gap-3 mt-4">
                <CustomButton
                  text="Reset"
                  iconRequired={false}
                  handleClick={() => {
                    setValues({
                      role_name: "",
                      data: [],
                    });
                    setSelectedPermissions({});
                  }}
                  type="btn-outline-primary"
                />
                {type === "add" ? (
                  <CustomButton
                    iconRequired={false}
                    type="btn-primary "
                    buttonType="submit"
                    handleClick={() => handleSubmit(values)}
                    disabled={!isValid || !dirty || isSaving}
                  >
                    Create Role
                    {isSaving && (
                      <span
                        className="spinner-border spinner-border-sm ms-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </CustomButton>
                ) : (
                  <CustomButton
                    iconRequired={false}
                    size="btn-lg"
                    type="btn-primary"
                    buttonType="submit"
                    handleClick={() => handleEdit(values)}
                    disabled={!isValid || isSaving}
                  >
                    Update Role
                    {isSaving && (
                      <span
                        className="spinner-border spinner-border-sm ms-2 text-light"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </CustomButton>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </Formik>
  );
};

// const ParentComponent = ({
//   modules,
//   initialData,
//   values,
//   setFieldValue,
//   selectedPermissions,
//   setSelectedPermissions,
// }) => {
//   const memoizedSelectedModules = useMemo(() => {
//     return modules.map((module) => ({
//       module,
//       selectedModule:
//         initialData?.data?.find((item) => item.module === module.name) || {},
//     }));
//   }, [initialData, modules]);

//   const handlePermissionChange = useCallback(
//     (permissions) => {
//       const updatedPermissions = [...values.data];
//       const index = updatedPermissions.findIndex(
//         (item) => item.module === permissions.module,
//       );
//       if (index > -1) {
//         updatedPermissions[index] = permissions;
//       } else {
//         updatedPermissions.push(permissions);
//       }
//       setFieldValue("data", updatedPermissions);
//     },
//     [values.data, setFieldValue],
//   );

//   // const handlePermissionChange = useCallback(
//   //   (permissions) => {
//   //     const updatedPermissions = [...values.data];

//   //     // Find if the module exists in the `role_access`
//   //     const index = updatedPermissions.findIndex(
//   //       (item) => Object.keys(item)[0] === permissions.module,
//   //     );

//   //     // Get the existing permissions for the module, if available
//   //     const existingPermissions =
//   //       index > -1 ? updatedPermissions[index][permissions.module] : [];

//   //     // Filter the permissions that are set to true (view, add/edit, etc.)
//   //     const newPermissions = Object.keys(permissions)
//   //       .filter((key) => permissions[key]) // only keep permissions that are true
//   //       .map((key) => key); // use the actual permission names (e.g., view, add/edit)

//   //     // Combine existing permissions with new permissions, ensuring no duplicates
//   //     const updatedPermissionList = [
//   //       ...new Set([...existingPermissions, ...newPermissions]),
//   //     ];

//   //     // Update or add the module's permissions
//   //     if (index > -1) {
//   //       updatedPermissions[index] = {
//   //         [permissions.module]: updatedPermissionList,
//   //       };
//   //     } else {
//   //       updatedPermissions.push({
//   //         [permissions.module]: updatedPermissionList,
//   //       });
//   //     }

//   //     // Set the updated permissions in the form state
//   //     setFieldValue("data", updatedPermissions);
//   //   },
//   //   [values.data, setFieldValue],
//   // );

//   // const handlePermissionChange = useCallback(
//   //   (permissions) => {
//   //     const updatedPermissions = [...values.data];

//   //     // Find if the module exists in the `role_access`
//   //     const index = updatedPermissions.findIndex(
//   //       (item) => Object.keys(item)[0] === permissions.module,
//   //     );

//   //     // Filter out the "module" key and only include valid permissions
//   //     const newPermissions = Object.keys(permissions)
//   //       .filter((key) => key !== "module" && permissions[key]) // exclude "module" and only keep permissions set to true
//   //       .map((key) => key); // keep the permission name (e.g., "view", "add/edit")

//   //     // Get existing permissions for the module if available
//   //     const existingPermissions =
//   //       index > -1 ? updatedPermissions[index][permissions.module] : [];

//   //     // Combine the existing permissions with the new permissions, avoiding duplicates
//   //     const updatedPermissionList = [
//   //       ...new Set([...existingPermissions, ...newPermissions]),
//   //     ];

//   //     if (updatedPermissionList.length > 0) {
//   //       // Update or add the module's permissions if they are not empty
//   //       if (index > -1) {
//   //         updatedPermissions[index] = {
//   //           [permissions.module]: updatedPermissionList,
//   //         };
//   //       } else {
//   //         updatedPermissions.push({
//   //           [permissions.module]: updatedPermissionList,
//   //         });
//   //       }
//   //     } else if (index > -1) {
//   //       // Remove modules with empty permissions
//   //       updatedPermissions.splice(index, 1);
//   //     }

//   //     // Set the updated permissions in the form state
//   //     setFieldValue(
//   //       "data",
//   //       updatedPermissions.filter((item) => Object.values(item)[0].length > 0),
//   //     );
//   //   },
//   //   [values.data, setFieldValue],
//   // );

//   // Helper function to map is_create/is_edit/etc. to actual permission types
//   const mapPermissionType = (key) => {
//     switch (key) {
//       case "is_create":
//         return "add/edit";
//       case "is_edit":
//         return "add/edit";
//       case "is_delete":
//         return "delete";
//       case "is_view":
//         return "view";
//       case "is_export":
//         return "export";
//       default:
//         return "unknown";
//     }
//   };

//   return (
//     <div>
//       <table className="permission-table">
//         <thead>
//           <tr>
//             <th>Module Name</th>
//             <th>View</th>
//             <th>Add/Edit</th>
//             <th>Export</th>
//             <th>Delete</th>
//           </tr>
//         </thead>
//         <tbody>
//           {memoizedSelectedModules.map(({ module, selectedModule }) => (
//             <PermissionSelector
//               key={module.name}
//               label={module.name}
//               identifier={module.identifier}
//               selected={selectedModule}
//               onChange={handlePermissionChange}
//               selectedPermissions={selectedPermissions}
//               setSelectedPermissions={setSelectedPermissions}
//             />
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// const ParentComponent = ({ modules, initialData, values, setFieldValue }) => {
//   const [selectAllPermissions, setSelectAllPermissions] = useState({
//     view: false,
//     "add/edit": false,
//     export: false,
//     delete: false,
//   });

//   const memoizedSelectedModules = useMemo(() => {
//     return modules.map((module) => ({
//       module,
//       selectedModule:
//         initialData?.data?.find((item) => item.module === module.name) || {},
//     }));
//   }, [initialData, modules]);

//   const handlePermissionChange = useCallback(
//     (permissions) => {
//       const updatedPermissions = [...values.data];
//       const index = updatedPermissions.findIndex(
//         (item) => item.module === permissions.module,
//       );
//       if (index > -1) {
//         updatedPermissions[index] = permissions;
//       } else {
//         updatedPermissions.push(permissions);
//       }
//       setFieldValue("data", updatedPermissions);
//     },
//     [values.data, setFieldValue],
//   );

//   const handleSelectAllChange = (type) => {
//     const newSelectAll = !selectAllPermissions[type];
//     setSelectAllPermissions((prev) => ({ ...prev, [type]: newSelectAll }));

//     const updatedModules = memoizedSelectedModules.map(
//       ({ module, selectedModule }) => {
//         const updatedPermissions = {
//           ...selectedModule,
//           [type]: newSelectAll,
//         };
//         handlePermissionChange({ ...updatedPermissions, module: module.name });
//         return updatedPermissions;
//       },
//     );

//     setFieldValue("data", updatedModules);
//   };

//   return (
//     <div>
//       <table className="permission-table">
//         <thead>
//           <tr>
//             <th>Name</th>
//             <th>
//               View
//               {/* <Checkbox
//                 checked={selectAllPermissions.view}
//                 onChange={() => handleSelectAllChange("view")}
//               /> */}
//               <input
//                 type="checkbox"
//                 checked={selectAllPermissions.view}
//                 onChange={() => handleSelectAllChange("view")}
//               />
//             </th>
//             <th>
//               Add/Edit
//               {/* <Checkbox
//                 checked={selectAllPermissions["add/edit"]}
//                 onChange={() => handleSelectAllChange("add/edit")}
//               /> */}
//             </th>
//             <th>
//               Export
//               {/* <Checkbox
//                 checked={selectAllPermissions.export}
//                 onChange={() => handleSelectAllChange("export")}
//               /> */}
//             </th>
//             <th>
//               <div>
//                 Delete
//                 {/* <Checkbox
//                   checked={selectAllPermissions.delete}
//                   onChange={() => handleSelectAllChange("delete")}
//                 /> */}
//               </div>
//             </th>
//             <th></th>
//           </tr>
//         </thead>
//         <tbody>
//           {memoizedSelectedModules.map(({ module, selectedModule }) => (
//             <PermissionSelector
//               key={module.name}
//               label={module.name}
//               identifier={module.identifier}
//               selected={selectedModule}
//               onChange={handlePermissionChange}
//             />
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// const ParentComponent = ({
//   modules,
//   initialData,
//   values,
//   setFieldValue,
//   selectedPermissions,
//   setSelectedPermissions,
// }) => {
//   const [selectAll, setSelectAll] = useState({
//     view: false,
//     "add/edit": false,
//     export: false,
//     delete: false,
//   });

//   console.log("Select All :", values.data);

//   const memoizedSelectedModules = useMemo(() => {
//     return modules.map((module) => ({
//       module,
//       selectedModule:
//         initialData?.data?.find((item) => item.module === module.name) || {},
//     }));
//   }, [initialData, modules]);

//   console.log("Memorized :", memoizedSelectedModules);

//   const handlePermissionChange = useCallback(
//     (permissions) => {
//       const updatedPermissions = [...values.data];
//       const index = updatedPermissions.findIndex(
//         (item) => item.module === permissions.module,
//       );
//       if (index > -1) {
//         updatedPermissions[index] = permissions;
//       } else {
//         updatedPermissions.push(permissions);
//       }
//       setFieldValue("data", updatedPermissions);
//     },
//     [values.data, setFieldValue],
//   );

//   const handleSelectAll = (permissionType) => {
//     const newValue = !selectAll[permissionType];

//     // Update the selectAll state
//     setSelectAll((prev) => ({ ...prev, [permissionType]: newValue }));

//     // Update all modules to reflect the selectAll state
//     const updatedPermissions = memoizedSelectedModules.map(({ module }) => ({
//       module: module.name,
//       [permissionType]: newValue,
//     }));

//     // Merge updated permissions with existing values
//     const mergedPermissions = values.data.map((item) => {
//       const update = updatedPermissions.find(
//         (updated) => updated.module === item.module,
//       );
//       return update ? { ...item, ...update } : item;
//     });

//     // Add any new modules that aren't in the current data
//     const newModules = updatedPermissions.filter(
//       (updated) => !values.data.some((item) => item.module === updated.module),
//     );

//     setFieldValue("data", [...mergedPermissions, ...newModules]);
//   };

//   return (
//     <div>
//       <table className="permission-table">
//         <thead>
//           <tr>
//             <th>Module Name</th>
//             <th>
//               <input
//                 type="checkbox"
//                 checked={selectAll.view}
//                 onChange={() => handleSelectAll("view")}
//               />
//               View
//             </th>
//             <th>
//               <input
//                 type="checkbox"
//                 checked={selectAll["add/edit"]}
//                 onChange={() => handleSelectAll("add/edit")}
//               />
//               Add/Edit
//             </th>
//             <th>
//               <input
//                 type="checkbox"
//                 checked={selectAll.export}
//                 onChange={() => handleSelectAll("export")}
//               />
//               Export
//             </th>
//             <th>
//               <input
//                 type="checkbox"
//                 checked={selectAll.delete}
//                 onChange={() => handleSelectAll("delete")}
//               />
//               Delete
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {memoizedSelectedModules.map(({ module, selectedModule }) => (
//             <PermissionSelector
//               key={module.name}
//               label={module.name}
//               identifier={module.identifier}
//               selected={selectedModule}
//               onChange={handlePermissionChange}
//               selectedPermissions={selectedPermissions}
//               setSelectedPermissions={setSelectedPermissions}
//             />
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

const ParentComponent = ({
  modules,
  initialData,
  values,
  setFieldValue,
  selectedPermissions,
  setSelectedPermissions,
}) => {
  const [selectAll, setSelectAll] = useState({
    view: false,
    "add/edit": false,
    export: false,
    delete: false,
  });

  console.log("Values :", values.data);

  // Dynamically track `selectedModule` based on `values.data`
  const selectedModules = useMemo(() => {
    return modules.map((module) => {
      const selected = values.data.find((item) => item.module === module.name);
      return {
        module,
        selectedModule: selected || { module: module.name },
      };
    });
  }, [modules, values.data]);

  const handlePermissionChange = useCallback(
    (permissions) => {

      if(permissions?.['add/edit'] && !permissions?.['view']){
        permissions = {...permissions,view:true, addedFromAdd: true}
      } else if (!permissions?.['add/edit'] && permissions.addedFromAdd && permissions?.['view']) {
        delete permissions.addedFromAdd;
        permissions = {...permissions,view: false}
      }
      const updatedPermissions = [...values.data];
      const index = updatedPermissions.findIndex(
        (item) => item.module === permissions.module,
      );
      if (index > -1) {
        updatedPermissions[index] = permissions;
      } else {
        updatedPermissions.push(permissions);
      }
      setFieldValue("data", updatedPermissions);
    },
    [values.data, setFieldValue],
  );

  const handleSelectAll = (permissionType) => {

    console.log('permission',permissionType)
    const allSelected = selectedModules.every(
      ({ selectedModule }) => selectedModule[permissionType],
    );
    const newValue = !allSelected; // If all are selected, unselect; if some are selected, select all.

    // Update the selectAll state
    setSelectAll((prev) => ({ ...prev, [permissionType]: newValue }));

    // Update all modules to reflect the selectAll state
    const updatedPermissions = modules.map((module) => {
      const updatedModule = {
        module: module.name,
        [permissionType]: newValue,
      };
  
      // If permissionType is 'add' or 'edit', also toggle the 'view' permission
      if (permissionType === "add/edit") {
        updatedModule.view = newValue;
      }

      // console.log('sel',selectedModules)

      // console.log('checkk ',updatedModule.find(( module ) => module?.selectedModule.view))
      // if ((permissionType === "add/edit") && newValue) {
      //   if (!selectedModules.find(({ selectedModule }) => selectedModule.view)?.selectedModule.view) {
      //     updatedModule.view = true;
      //     updatedModule.addedFromAdd = true; // Set the flag
      //   }
      // }
  
      return updatedModule;
    });

    // Merge updated permissions with existing values
    const mergedPermissions = values.data.map((item) => {
      const update = updatedPermissions.find(
        (updated) => updated.module === item.module,
      );
      return update ? { ...item, ...update } : item;
    });

    // Add any new modules that aren't in the current data
    const newModules = updatedPermissions.filter(
      (updated) => !values.data.some((item) => item.module === updated.module),
    );

    setFieldValue("data", [...mergedPermissions, ...newModules]);
  };

  // Check if all checkboxes are selected for a permission type
  const checkIfAllSelected = (permissionType) => {
    const allSelected = selectedModules.every(
      ({ selectedModule }) => selectedModule[permissionType],
    );
    const someSelected = selectedModules.some(
      ({ selectedModule }) => selectedModule[permissionType],
    );

    return allSelected ? true : someSelected ? null : false;
  };

  console.log('selected',selectedModules)

  return (
    <div>
      <table className="permission-table">
        <thead>
          <tr>
            <th>Module Name</th>
            <th>
              {/* <input
                type="checkbox"
                checked={checkIfAllSelected("view")}
                onChange={() => handleSelectAll("view")}
                disabled={selectedModules.length === 0}
              /> */}
              <div className="d-flex gap-2 align-items-center">
                <div
                  className={
                    checkIfAllSelected("view")
                      ? "role_checked"
                      : "role_unchecked "
                  }
                  onClick={() => handleSelectAll("view")}
                >
                  {checkIfAllSelected("view") ? <></> : <MinusIcon />}
                </div>
                View
              </div>
            </th>
            <th>
              <div className="d-flex gap-2 align-items-center">
                <div
                  className={
                    checkIfAllSelected("add/edit")
                      ? "role_checked"
                      : "role_unchecked "
                  }
                  onClick={() => handleSelectAll("add/edit")}
                >
                  {checkIfAllSelected("add/edit") ? <></> : <MinusIcon />}
                </div>
                Add/Edit
              </div>
            </th>
            <th>
              <div className="d-flex gap-2 align-items-center">
                <div
                  className={
                    checkIfAllSelected("export")
                      ? "role_checked"
                      : "role_unchecked "
                  }
                  onClick={() => handleSelectAll("export")}
                >
                  {checkIfAllSelected("export") ? <></> : <MinusIcon />}
                </div>
                Export
              </div>
            </th>
            <th>
              <div className="d-flex gap-2 align-items-center">
                <div
                  className={
                    checkIfAllSelected("delete")
                      ? "role_checked"
                      : "role_unchecked "
                  }
                  onClick={() => handleSelectAll("delete")}
                >
                  {checkIfAllSelected("delete") ? <></> : <MinusIcon />}
                </div>
                Delete
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {selectedModules.map(({ module, selectedModule }) => (
            <PermissionSelector
              key={module.name}
              label={module.name}
              identifier={module.identifier}
              allowed={module.allowed}
              selected={selectedModule}
              onChange={handlePermissionChange}
              selectedPermissions={selectedPermissions}
              setSelectedPermissions={setSelectedPermissions}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Add;
